import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Translation,
  TranslocoService,
  getBrowserLang,
} from '@jsverse/transloco';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { selectCurrentMenu } from 'src/app/menus/menu-edit/ngrx/menu-edit.selectors';
import { State } from 'src/app/reducers';
import { LocationGroup } from 'src/app/shared/Models/location';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { setUser } from 'src/app/shared/user/ngrx/user.actions';
import { EMPTY } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { PreviewComponent } from '../Components/pdf-preview/pdf-preview.component';
import { GeneralFormErrors } from '../Models/authentication';
import { Additive, Allergen, Label } from '../Models/declarations';
import { Diet, ResultWithCategories } from '../Models/diet';
import { CondensedDish, Dish } from '../Models/dish';
import { Results } from '../Models/generics';
import { Ingredient } from '../Models/ingredients';
import { SimpleLocation } from '../Models/location';
import { GenerateArchiveOptions, StyleCategories } from '../Models/menu';
import { MTFont } from '../Models/mtfont';
import { OnboardingTemplate } from '../Models/onboarding_template';
import { NO_PARTNER, PARTNERS } from '../Models/partners';
import { Recipe } from '../Models/recipe';
import { Ruleset } from '../Models/ruleset';
import { CondensedSeparator } from '../Models/separator';
import { Layout } from '../Models/template';
import { Type } from '../Models/type';
import { Task, UserModule } from '../Models/user';
import { GenericsService } from '../Services/generics/generics.service';
import { UtilsService } from '../Services/utils/utils.service';
import { extractGeneralFormErrors, flattenObject } from '../utils.functions';
import * as SharedActions from './shared.actions';
import {
  selectTasks,
  selectCurrentLocation,
  selectSimilarDishesAdditivesNextPageUrl,
  selectSimilarDishesAllergensNextPageUrl,
} from './shared.selectors';
import { Language } from '../Models/models';
import { DateAdapter } from '@angular/material/core';
import { de, enUS, es, fr, it } from 'date-fns/locale';
import { MatIconRegistry } from '@angular/material/icon';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { Collection } from '../Models/integration';

const NON_AUTH_FORM_ERRORS = [`message`, `token`, `error`];

@Injectable()
export class SharedEffects {
  afterEffectsInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mergeMap(() => {
        this.matIconRegistry.setDefaultFontSetClass(
          'material-symbols-outlined',
        );
        const lang = Language(getBrowserLang());
        const actions: Action[] = [SharedActions.setInterfaceLang({ lang })];
        const userModules = this.utils.userModules;
        if (userModules)
          actions.push(SharedActions.setUserModules({ userModules }));
        return actions;
      }),
      catchError(() => EMPTY),
    ),
  );

  setInterfaceLang$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.setInterfaceLang),
      tap(({ lang }) => {
        this.transloco.setActiveLang(lang);
        this.dateAdapter.setLocale(this.localeMap[lang]);
      }),
      mergeMap(({ lang }) => [SharedActions.setGlobalMetaTags({ lang })]),
      catchError(() => EMPTY),
    ),
  );

  setGlobalMetaTags$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.setGlobalMetaTags),
        tap(({ lang }) => {
          // Set the language attribute on the <html> tag
          this._document.querySelector('html').setAttribute('lang', lang);
        }),
        switchMap(({ lang }) =>
          this.transloco.selectTranslateObject<{
            [key: string]: string;
          }>('app.meta', undefined, lang),
        ),
        mergeMap((metaTags) => {
          if (!metaTags || typeof metaTags === 'string') return EMPTY;
          // Set all meta tags defined in the translations object
          Object.entries(metaTags).forEach(([key, value]) => {
            this.meta.updateTag({
              name: key,
              content: value,
            });
          });
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  showSnackbarMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.showSnackbarMessage),
        switchMap(({ errorCode, message, snackClass, button }) => {
          if (message && message !== `<`) {
            this.snackBar.open(message, button, {
              panelClass: snackClass,
              duration: 3000,
            });
          }
          if (errorCode === 0)
            this.snackBar.open(this.offlineErrorMessage, null, {
              duration: 3000,
            });
          if ([500, 502].includes(errorCode))
            this.snackBar.open(this.serverErrorMessage, null, {
              duration: 3000,
            });
          return [EMPTY];
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  handleHttpError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.handleHttpError),
      switchMap(({ error, formId, forceSnackbar }) => {
        if (!error?.error) return EMPTY;
        // handle form/field error
        let errors: GeneralFormErrors = extractGeneralFormErrors(error.error);
        if ([500, 502].includes(error.status)) {
          return [
            SharedActions.showSnackbarMessage({
              message: this.serverErrorMessage,
            }),
          ];
        }
        if (error.status === 400 && errors && formId && !forceSnackbar) {
          if (NON_AUTH_FORM_ERRORS.some((key) => key in errors)) {
            errors = Object.entries(errors).reduce((acc, [, value]) => {
              acc['non_field_errors'] = value;
              return acc;
            }, {});
          }
          const actionsToDispatch = [];
          // TODO: setFieldErrors and selectGeneralFormErrors are only partially implemented (requires shared server-field directives)
          // NOTE: renamed setGeneralFormErrors -> setFieldErrors; rename setFormErrors -> setFormErrors
          if (Object.keys(errors).length > 1 || !errors?.non_field_errors) {
            actionsToDispatch.push(
              SharedActions.setFieldErrors({ payload: errors }),
            );
          }
          if (
            errors.non_field_errors &&
            typeof errors.non_field_errors === 'string'
          ) {
            actionsToDispatch.push(
              SharedActions.setFormErrors({
                error: { form_id: formId, message: errors.non_field_errors },
              }),
            );
          }
          return actionsToDispatch as [];
        }
        const flatObj = flattenObject(error.error);
        const firstErrorMessage =
          typeof flatObj === 'string' ? flatObj : Object.values(flatObj)[0];
        if (firstErrorMessage)
          return [
            SharedActions.showSnackbarMessage({ message: firstErrorMessage }),
          ];
        return EMPTY;
      }),
      catchError(() => EMPTY),
    ),
  );

  showPreviewDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.showPreviewDialog),
        withLatestFrom(this.store.select(selectCurrentLocation)),
        switchMap(
          ([
            {
              url,
              baseLanguage,
              numberLanguages,
              langs,
              multiRequired,
              hideLangs,
              params,
              htmlPreview,
            },
            location,
          ]) => {
            const pdfPreview = this.dialog.open(PreviewComponent, {
              autoFocus: false,
              width: '890px',
              maxWidth: '90vw',
              height: '95%',
            });
            pdfPreview.componentRef.setInput('htmlPreview', htmlPreview);
            const instance = pdfPreview.componentInstance;
            instance.url = url;
            instance.params = UtilsService.addLocationParam(location, params);
            instance.baseLanguage = baseLanguage;
            instance.numberLanguages = numberLanguages || 1;
            instance.langs = langs || [];
            instance.multiRequired = multiRequired;
            instance.hideLangs = hideLangs;
            return EMPTY;
          },
        ),
        catchError((error) => [SharedActions.handleHttpError({ error })]),
      ),
    { dispatch: false },
  );

  fetchPreviewHtml$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchPreviewHtml),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ url, params }, location]) =>
        this.genericsService
          .get(
            url,
            UtilsService.addLocationParam(location, {
              ...params,
              response: 'html',
            }),
            undefined,
            'text',
          )
          .pipe(
            mergeMap((html: string) => [
              SharedActions.setPreviewHtml({ html }),
            ]),
            catchError((error) => [SharedActions.handleHttpError(error)]),
          ),
      ),
    ),
  );

  fetchPreviewPdfHtml$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchPreviewPdfHtml),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ url, params }, location]) =>
        this.genericsService
          .get(
            url,
            UtilsService.addLocationParam(location, {
              ...params,
              response: 'html',
            }),
            undefined,
            'text',
          )
          .pipe(
            mergeMap((html: string) => [
              SharedActions.setPreviewPdfHtml({ html }),
            ]),
            catchError((error) => [SharedActions.handleHttpError(error)]),
          ),
      ),
    ),
  );

  onUserCategoryChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setUser),
        filter(({ user }) => !!user?.category),
        map(({ user }) => [
          `assets/i18n/${user.settings.language}_${user.category}.json`,
          user.settings.language,
        ]),
        distinctUntilChanged((prev, curr) => prev[0] === curr[0]),
        switchMap(([path, lang]) =>
          this.http.get(path).pipe(
            map((translations) => [translations, lang]),
            catchError((error) => {
              return [SharedActions.handleHttpError({ error })];
            }),
          ),
        ),
        map((dataOrError) => {
          if (Array.isArray(dataOrError)) {
            const translations = dataOrError[0];
            const lang = dataOrError[1] as string;
            this.transloco.setTranslation(translations as Translation, lang, {
              merge: true,
            });
          }
        }),
      ),
    { dispatch: false },
  );

  getAllLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getAllLocations),
      switchMap(({ params }) =>
        this.genericsService
          .get<SimpleLocation[]>(this.configService.locations, params)
          .pipe(
            mergeMap((locations) => [
              SharedActions.setAllLocations({ payload: locations }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  getAllLocationGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getAllLocationGroups),
      switchMap(({ params }) =>
        this.genericsService
          .get<LocationGroup[]>(this.configService.locationGroups, params)
          .pipe(
            mergeMap((locations) => [
              SharedActions.setAllLocationGroups({ payload: locations }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchTemplates),
      tap(() => {
        this.store.dispatch(SharedActions.setTemplatesState({ loading: true }));
      }),
      switchMap(() =>
        this.genericsService
          .get<OnboardingTemplate[]>(this.configService.onboardingTemplates)
          .pipe(
            mergeMap((data) => [
              SharedActions.setTemplates({ data }),
              SharedActions.setTemplatesState({ loading: false }),
            ]),
            catchError((error) => [
              SharedActions.setTemplatesState({ loading: false }),
              SharedActions.handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  fetchTemplatesMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchTemplatesMenu),
      switchMap(({ current_menu }) =>
        this.genericsService
          .get<OnboardingTemplate[]>(this.configService.onboardingTemplates, {
            current_menu,
          })
          .pipe(
            mergeMap((data) => [SharedActions.setTemplatesLocation({ data })]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchCondensedTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchCondensedLayouts),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ show_hidden, params }, currentMenu]) => {
        return this.genericsService
          .get<ResultWithCategories<Layout, StyleCategories>>(
            this.configService.layouts,
            {
              ...(currentMenu ? { current_menu: currentMenu.id } : {}),
              ...(params || { show_hidden }),
            },
          )
          .pipe(
            mergeMap(({ results, categories }) => [
              SharedActions.setCondensedLayouts({ payload: results }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          );
      }),
    ),
  );

  fetchOrderTakingLayouts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchOrderTakingLayouts),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ params }, currentMenu]) =>
        this.genericsService
          .get<Layout[]>(this.configService.layouts, {
            ...params,
            ...(currentMenu ? { current_menu: currentMenu?.id } : {}),
            category: 'order',
          })
          .pipe(
            mergeMap((layouts) => [
              SharedActions.setOrderTakingLayouts({ payload: layouts }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchUsedTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchUsedTemplates),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ params }, location]) =>
        this.genericsService
          .get<
            OnboardingTemplate[]
          >(this.configService.onboardingTemplates, UtilsService.addLocationParam(location, { ...params, used: true }))
          .pipe(
            mergeMap((templates) => [
              SharedActions.setUsedTemplates({ payload: templates }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchPartnerByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchPartnerByCode),
      switchMap(({ code }) => [
        SharedActions.setPartner({
          partner: PARTNERS.find((p) => p.code === code) || NO_PARTNER,
        }),
      ]),
    ),
  );

  uploadImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.uploadImage),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ actions, callback, image, url, params }, location]) =>
        this.genericsService
          .patch<
            FormData,
            any
          >(url, image, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((result) => {
              if (callback) callback(result);
              if (actions?.length)
                return actions.map(([action, key]) =>
                  action({ [key]: result }),
                );
              return [];
            }),
            catchError((error) => [SharedActions.handleHttpError(error)]),
          ),
      ),
    ),
  );

  fetchRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchRules),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ params }, location]) =>
        this.genericsService
          .get<
            Results<Ruleset>
          >(this.configService.rules, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((rules) => [SharedActions.setRules({ payload: rules })]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  sendSupportForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.sendSupportForm),
      switchMap(({ callback, files, ...data }) => {
        const formData = new FormData();
        Object.entries(data).forEach(([k, v]) => formData.append(k, v));
        if (files?.length) files.forEach((f) => formData.append('files', f));
        return this.genericsService
          .post<
            FormData,
            { message: string }
          >(this.configService.support, formData)
          .pipe(
            mergeMap(() => {
              callback?.();
              return EMPTY;
            }),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          );
      }),
    ),
  );

  fetchFonts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchFonts),
      switchMap(() =>
        this.genericsService.get<MTFont[]>(this.configService.fonts).pipe(
          mergeMap((fonts: MTFont[]) => [
            SharedActions.setFontsAsFilter({ fonts: fonts }),
          ]),
          catchError((error) => [SharedActions.handleHttpError({ error })]),
        ),
      ),
    ),
  );

  fetchDishesAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchDishesAutocomplete),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ params }, location]) =>
        this.genericsService
          .get<Results<CondensedDish>>(
            this.configService.dishes,
            UtilsService.addLocationParam(location, {
              condensed: true,
              ...params,
            }),
          )
          .pipe(
            mergeMap((dishes) => [
              SharedActions.setDishesAutocomplete({ payload: dishes }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchIngredientsAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchIngredientsAutocomplete),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ params }, location]) => {
        return this.genericsService
          .get<
            Results<Ingredient>
          >(this.configService.ingredients, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((res) => [
              SharedActions.setIngredientsAutocomplete({ payload: res }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          );
      }),
    ),
  );

  createIngredientAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.createIngredientAutocomplete),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ ingredient, params, onFulfilled }, location]) =>
        this.genericsService
          .post<
            Partial<Ingredient>,
            Ingredient
          >(this.configService.ingredients, ingredient, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((res) => {
              if (onFulfilled) onFulfilled(res);
              return [
                SharedActions.setIngredientsAutocomplete({
                  payload: {
                    results: [res],
                    count: 1,
                    next: null,
                    previous: null,
                  },
                }),
              ];
            }),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updateIngredientAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.updateIngredientAutocomplete),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ url, ingredient, params, onFulfilled }, location]) =>
        this.genericsService
          .patch<
            Partial<Ingredient>,
            Ingredient
          >(url, ingredient, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((res) => {
              if (onFulfilled) onFulfilled(res);
              return [];
            }),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  createDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.createDishAutocomplete),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ dish, clear, callback, errorCb }, location]) =>
        this.genericsService
          .post<
            Partial<Dish>,
            Dish
          >(this.configService.dishes, dish, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap((res) => {
              if (callback) callback(res);
              return clear
                ? []
                : [
                    SharedActions.setDishesAutocomplete({
                      payload: {
                        count: 1,
                        next: null,
                        previous: null,
                        results: [res as CondensedDish],
                      },
                    }),
                  ];
            }),
            catchError((error) => {
              if (errorCb) errorCb();
              return [SharedActions.handleHttpError({ error })];
            }),
          ),
      ),
    ),
  );

  fetchUserModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchUserModules),
      switchMap(() =>
        this.genericsService
          .get<UserModule[]>(this.configService.userModules)
          .pipe(
            tap((userModules) => {
              this.utils.userModules = userModules;
            }),
            mergeMap((userModules) => [
              SharedActions.setUserModules({ userModules }),
              SharedActions.setUserModulesLoaded(),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchAllergens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchAllergens),
      switchMap(() =>
        this.genericsService.get<Allergen[]>(this.configService.allergens).pipe(
          map((result) => {
            return SharedActions.setAllergens({ result });
          }),
          catchError((error) => [SharedActions.handleHttpError({ error })]),
        ),
      ),
    ),
  );

  fetchAdditives$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchAdditives),
      switchMap(() =>
        this.genericsService.get<Additive[]>(this.configService.additives).pipe(
          map((result) => {
            return SharedActions.setAdditives({ result });
          }),
          catchError((error) => [SharedActions.handleHttpError({ error })]),
        ),
      ),
    ),
  );

  fetchLabel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchLabels),
      switchMap(() =>
        this.genericsService.get<Label[]>(this.configService.labels).pipe(
          map((result) => {
            return SharedActions.setLabels({ result });
          }),
          catchError((error) => [SharedActions.handleHttpError({ error })]),
        ),
      ),
    ),
  );

  fetchDeclarations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchDeclarations),
      switchMap(() => [
        SharedActions.fetchAllergens(),
        SharedActions.fetchAdditives(),
        SharedActions.fetchLabels(),
      ]),
    ),
  );

  fetchArchives$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchArchives),
      withLatestFrom(this.store.select(selectTasks)),
      switchMap(([{}, previousTasks]) =>
        this.genericsService.get<Task[]>(this.configService.menuArchives).pipe(
          mergeMap((tasks) => {
            // check if any of the previousTasks is not ready but is now in the archives and ready
            tasks
              .filter((t) => [1, 3, 5].includes(t.task_type))
              .forEach((task) => {
                const archive = previousTasks.find((t) => t.id === task.id);
                if (archive && archive.ready && !task.ready) {
                  this.utils.refreshPdfView.next();
                }
              });
            // repeat if any of the tasks is not ready
            if (!!tasks?.find((archive) => !archive.ready && !archive.failed)) {
              setTimeout(
                () => this.store.dispatch(SharedActions.fetchArchives()),
                3000,
              );
            }
            return [SharedActions.setArchives({ archives: tasks })];
          }),
          catchError((error) => [SharedActions.handleHttpError(error)]),
        ),
      ),
    ),
  );

  changeArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.changeArchive),
      switchMap(({ archive }) =>
        this.genericsService
          .patch<Partial<Task>, Task>(archive.url, archive)
          .pipe(
            mergeMap(() => [SharedActions.fetchArchives()]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  createArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.createArchive),
      withLatestFrom(
        this.store.select(selectCurrentLocation),
        this.store.select(selectTasks),
      ),
      switchMap(([{ menu, task_type, options }, location, tasks]) => {
        const isDownloading = tasks.find(
          (el) =>
            el.object_id === menu.id &&
            el.task_type === task_type &&
            el.ready === false &&
            el.content_type === 'menu',
        );
        if (isDownloading) {
          return [
            SharedActions.showSnackbarMessage({
              message: this.taskInProgressMessage,
            }),
          ];
        }
        return this.genericsService
          .post<GenerateArchiveOptions, { message: string }>(
            `${menu.url}archive/`,
            {
              task_type,
              ...options,
            },
            UtilsService.addLocationParam(location),
          )
          .pipe(
            mergeMap(() => [SharedActions.fetchArchives()]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          );
      }),
    ),
  );

  fetchSeparatorAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchSeparatorAutocomplete),
      switchMap(({ params }) =>
        this.genericsService
          .get<Results<CondensedSeparator>>(this.configService.separators, {
            category: 'sec',
            condensed: true,
            ...params,
          })
          .pipe(
            mergeMap((separators) => [
              SharedActions.setAutocompleteSeparator({
                payload: separators.results,
              }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchDiets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchDiets),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ params }, location]) =>
        this.genericsService
          .get<Diet[]>(
            this.configService.diets,
            UtilsService.addLocationParam(location, {
              ...params,
              pagination: 'off',
            }),
          )
          .pipe(
            mergeMap((diets) => [SharedActions.setDiets({ diets })]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchTypes),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ params }, location]) =>
        this.genericsService
          .get<Type[]>(
            this.configService.types,
            UtilsService.addLocationParam(location, {
              ...params,
              pagination: 'off',
            }),
          )
          .pipe(
            mergeMap((data) => [SharedActions.setTypes({ data })]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchRecipesAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchRecipesAutocomplete),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ params }, location]) =>
        this.genericsService
          .get<Results<Recipe>>(
            this.configService.recipes,
            UtilsService.addLocationParam(location, {
              ...params,
              condensed: true,
            }),
          )
          .pipe(
            mergeMap((recipes) => [
              SharedActions.setRecipes({ recipes: recipes.results }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchSimilarDishes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchSimilarDishes),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      concatMap(([{ name, lang, containsOption, dish }, currentMenu]) => {
        const baseLang = currentMenu?.base_language || lang;
        const params = {
          category: dish.category,
          condensed: true,
          exclude: dish.id,
          [`${baseLang}_similar`]: name,
          [`has_${containsOption}`]: true,
          ...(currentMenu?.id ? { current_menu: currentMenu?.id } : {}),
          ...(lang && lang !== baseLang ? { [`${lang}_isempty`]: false } : {}),
        };
        return this.genericsService
          .get<Results<Dish>>(this.configService.dishes, params)
          .pipe(
            mergeMap((result) => {
              const action =
                containsOption === 'additives'
                  ? SharedActions.setSimilarDishesAdditives
                  : SharedActions.setSimilarDishesAllergens;
              return [action({ result })];
            }),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          );
      }),
    ),
  );

  fetchMoreDishes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchMoreDishes),
      withLatestFrom(
        this.store.select(selectSimilarDishesAllergensNextPageUrl),
        this.store.select(selectSimilarDishesAdditivesNextPageUrl),
      ),
      switchMap(([{ typeOfDish }, nextPageUrl, nextPageUrlAdditives]) => {
        const nextPage =
          typeOfDish === 'additives' ? nextPageUrlAdditives : nextPageUrl;
        if (!nextPage) return undefined;
        return this.genericsService.get<Results<Dish>>(nextPage).pipe(
          mergeMap(({ next: nextUrl, results: dishes }) => {
            if (typeOfDish === 'additives') {
              return [
                SharedActions.addMoreDishesAdditives({ dishes, nextUrl }),
              ];
            } else {
              return [SharedActions.addMoreDishes({ dishes, nextUrl })];
            }
          }),
          catchError((error) => [SharedActions.handleHttpError({ error })]),
        );
      }),
    ),
  );

  fetchCollections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.fetchCollections),
      switchMap(() =>
        this.genericsService
          .get<
            Collection[]
          >(this.configService.collections, { pagination: 'off', condensed: true })
          .pipe(
            mergeMap((collections) => [
              SharedActions.setCollections({
                collections,
              }),
            ]),
            catchError((error) => [SharedActions.handleHttpError({ error })]),
          ),
      ),
    ),
  );

  private readonly serverErrorMessageKey = 'shared.errors.server-error';
  private serverErrorMessage: string;
  private readonly offlineErrorMessageKey = 'shared.errors.offline';
  private offlineErrorMessage: string;
  private readonly taskInProgressKey = 'shared.errors.task-in-progress';
  private taskInProgressMessage: string;

  localeMap = {
    en: enUS,
    de: de,
    fr: fr,
    es: es,
    it: it,
  };

  constructor(
    @Inject(DOCUMENT) private _document,
    private actions$: Actions,
    private configService: ConfigService,
    private dateAdapter: DateAdapter<Date>,
    private dialog: MatDialog,
    private genericsService: GenericsService,
    private http: HttpClient,
    private matIconRegistry: MatIconRegistry,
    private meta: Meta,
    private snackBar: MatSnackBar,
    private store: Store<State>,
    private transloco: TranslocoService,
    private utils: UtilsService,
  ) {
    this.getServerErrorMessage();
    this.transloco.langChanges$.subscribe(() => [
      this.getOfflineErrorMessage(),
      this.getServerErrorMessage(),
    ]);
  }

  getOfflineErrorMessage(): void {
    this.transloco
      .selectTranslate(this.offlineErrorMessageKey)
      .subscribe((msg) => (this.offlineErrorMessage = msg));
  }

  getServerErrorMessage(): void {
    this.transloco
      .selectTranslate(this.serverErrorMessageKey)
      .subscribe((msg) => (this.serverErrorMessage = msg));
  }

  getTaskInProgressMessage(): void {
    this.transloco
      .selectTranslate(this.taskInProgressKey)
      .subscribe((msg) => (this.taskInProgressMessage = msg));
  }
}
