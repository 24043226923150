import { DecimalPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoPipe } from '@jsverse/transloco';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { Task } from '../../Models/user';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'archive-bar',
  templateUrl: './archive-bar.component.html',
  styleUrls: [`./archive-bar.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    SpinnerComponent,
    MtTooltipDirective,
    MatProgressBarModule,
    DecimalPipe,
    DatePipe,
    TranslocoPipe,
  ],
})
export class ArchiveBarComponent implements OnChanges, OnDestroy {
  @Input() task: Task;
  @Output() closed = new EventEmitter<void>();
  @Output() download = new EventEmitter<{
    task: Task;
    onFulfilled: () => void;
  }>();

  expectedDuration: number;
  expectedDurationPercentage: number;
  expectedDurationRemaining: number;
  intervalId: NodeJS.Timeout;
  taskDownloading = false;
  taskMessage = {
    1: 'import',
    2: 'products',
    3: 'menus',
    4: 'consumers',
    5: 'menus',
  };
  taskMessageTranslationKey = '';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('task' in changes && this.task) {
      this.taskMessageTranslationKey = this.taskMessage[this.task.task_type];
    }
    if (
      'task' in changes &&
      this.task &&
      this.task.expected_duration &&
      !this.task.ready &&
      !this.task.failed
    ) {
      if (changes.task.previousValue?.id !== this.task.id) {
        this.expectedDuration = this.expectedDurationToMiliseconds(this.task);
      }
      [this.expectedDurationRemaining, this.expectedDurationPercentage] =
        this.calculateCurrentDurationPercentage(this.task);
      clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.expectedDurationRemaining -= 500;
        if (this.expectedDurationPercentage <= 100)
          this.expectedDurationPercentage +=
            100 / (this.expectedDuration / 500);
      }, 500);
    }
  }

  downloadArchive(): void {
    this.taskDownloading = true;
    const onFulfilled = () => {
      this.taskDownloading = false;
      this.changeDetectorRef.detectChanges();
    };
    this.download.emit({
      task: this.task,
      onFulfilled,
    });
  }

  expectedDurationToMiliseconds(task: Task): number {
    const expectedDurationParts = task.expected_duration.split(':');
    const expectedDurationSeconds =
      parseInt(expectedDurationParts[0]) * 60 * 60 +
      parseInt(expectedDurationParts[1]) * 60 +
      parseFloat(expectedDurationParts[2]);
    return expectedDurationSeconds * 1000;
  }

  calculateCurrentDurationPercentage(task: Task): [number, number] {
    const now = new Date();
    const processingStart = new Date(task.processing_start);
    const currentDuration = now.getTime() - processingStart.getTime();

    const expectedRemaining = this.expectedDuration - currentDuration;
    const expectedRemainingPercentage =
      (currentDuration / this.expectedDuration) * 100;
    return [expectedRemaining, expectedRemainingPercentage];
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
