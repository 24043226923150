import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import {
  displayOptions,
  displayOptionsComplete,
  ordertakingOptions,
} from 'src/app/shared/constants/menudish';
import { Variant } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { BackgroundImage } from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { Fulfillable } from 'src/app/shared/Models/models';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { SelectFieldComponent } from './select-field/select-field.component';
import { BooleanFieldComponent } from './boolean-field/boolean-field.component';
import { SelectImageComponent } from '../../../../shared/select-image/select-image.component';
import { ShowAndUploadImageComponent } from '../../../../../shared/Components/show-and-upload-image/show-and-upload-image.component';
import { DateFieldComponent } from './date-field/date-field.component';
import { VariantsComponent } from '../../../../../shared/Components/variants/variants.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'other-tab',
  templateUrl: './other-tab.component.html',
  styleUrls: ['./other-tab.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    VariantsComponent,
    DateFieldComponent,
    ShowAndUploadImageComponent,
    SelectImageComponent,
    BooleanFieldComponent,
    SelectFieldComponent,
    TranslocoPipe,
  ],
})
export class OtherTabComponent implements OnChanges, OnInit, OnDestroy {
  @Input() currencySymbol: string;
  @Input() dish: MenuDish;
  @Input() lang: ContentLanguage;
  @Input() translations: object;
  @Input() backgroundImages: BackgroundImage[];

  @Output() changeField = new EventEmitter<{
    dish: MenuDish;
    data: DeepPartial<MenuDish> & { onFulfilled?: () => void };
  }>();
  @Output() showAllBackgrounds = new EventEmitter();
  @Output() uploadBackgroundImage = new EventEmitter<Fulfillable<File>>();

  currentMenudish: MenuDish;
  private destroyed$ = new Subject<void>();
  initialVariantValue = null;
  loadingBars = {
    date: false,
    description: false,
    description_secondary: false,
    display_options: false,
    highlight: false,
    image: false,
    ordertaking_options: false,
    ordertaking_min_quantity: false,
    pagebreak: false,
    empty_page: false,
    price: false,
    spacer: false,
    wine: false,
  };
  otherTabForm = new FormGroup({
    description: new FormControl(''),
    description_secondary: new FormControl(''),
    display_options: new FormControl<null | number>(null),
    highlight: new FormControl(false),
    image: new FormControl<null | File>(null),
    ordertaking_options: new FormControl<null | number>(null),
    ordertaking_min_quantity: new FormControl<number>(1),
    pagebreak: new FormControl(false),
    empty_page: new FormControl(false),
    price: new FormControl<null | number>(null),
    spacer: new FormControl(false),
    wine: new FormControl(''),
    date: new FormControl<Date | null>(null),
    background_span_page: new FormControl(false),
  });
  selectOptions = {
    displayOptions: displayOptions,
    displayOptionsComplete: displayOptionsComplete,
    ordertakingOptions: ordertakingOptions,
  };
  showSpinner = false;
  showVariants = false;
  backgroundUpdating = false;
  hideLastImage = false;
  rtl = false;
  showImageSpinner = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dateAdapter: DateAdapter<Date>,
    private utils: UtilsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('lang' in changes && this.lang) {
      this.rtl = rtlLangs.includes(this.lang);
    }
    if (
      'dish' in changes &&
      (!changes.dish.previousValue ||
        changes.dish.previousValue.id !== changes.dish.currentValue.id)
    ) {
      // if the form is dirty, submit the changes first
      if (this.otherTabForm.dirty) {
        this.onValueChanged(this.otherTabForm.value);
      }
      this.backgroundUpdating = false;
      this.initialVariantValue = null;
      Object.keys(this.loadingBars).forEach(
        (key) => (this.loadingBars[key] = false),
      );
      if (
        ('backgroundImages' in changes || 'dish' in changes) &&
        this.backgroundImages &&
        this.dish
      ) {
        this.hideLastImage =
          !!this.dish?.user_details.background &&
          this.backgroundImages.length > 2 &&
          this.backgroundImages.filter(
            (img: BackgroundImage) =>
              img.id === this.dish?.user_details.background,
          ).length === 0;
      }
      if (this.dish) this.showVariants = !!this.dish.variants.length;
      if (this.dish && this.lang) this.setFormOnChange();
    }
  }

  ngOnInit(): void {
    this.otherTabForm.valueChanges
      .pipe(
        tap(() => (this.currentMenudish = this.dish)),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
        debounceTime(400),
        takeUntil(this.destroyed$),
      )
      .subscribe((data: typeof this.otherTabForm.value) =>
        this.onValueChanged(data),
      );
  }

  addVariant(): void {
    this.initialVariantValue = this.otherTabForm.controls.price.value;
    this.otherTabForm.controls.price.patchValue(null, { emitEvent: false });
    this.showVariants = true;
  }

  blurInput = (target: EventTarget): void => (<HTMLElement>target).blur();

  hasModules = (code: string): boolean => this.utils.hasModules(code);

  onValueChanged(data: typeof this.otherTabForm.value): void {
    if (!this.otherTabForm.valid) return undefined;

    const { date, ...otherData } = data;
    const loading: string[] = [];
    const newData: DeepPartial<MenuDish> = {};

    // Parse the date field and add it to the root of the newData object
    const parsedDate = date
      ? this.dateAdapter.format(date, 'yyyy-MM-dd')
      : null;
    if (parsedDate !== this.dish['date']) {
      newData['date'] = parsedDate;
      loading.push(...this.setLoadingBar('date', true));
    }

    // Helper function to update the user_details object inside the newData object
    const updateField = (
      key: keyof typeof this.otherTabForm.value,
      value: typeof this.otherTabForm.value,
    ) => {
      let langKey: string = key;
      if (key === 'description' || key === 'description_secondary') {
        langKey = `${key}_${this.lang}` as string;
      }
      if (this.currentMenudish.user_details[langKey] === value) return;
      if (!newData['user_details']) newData['user_details'] = {};
      newData['user_details'][langKey] = value;
      loading.push(...this.setLoadingBar(key, true));
    };

    // Loop through the form fields and update the user_details object inside the newData object
    Object.keys(this.otherTabForm.controls)
      .filter(
        (key) =>
          key !== 'date' &&
          key in otherData &&
          this.otherTabForm.controls[key].dirty,
      )
      .forEach((key: keyof typeof this.otherTabForm.value) => {
        // Parse the price field
        if (key === 'price') {
          otherData[key] = this.parseTextPrice(otherData[key]);
        }
        // Set the min quantity to 1 if it's empty
        if (key === 'ordertaking_min_quantity' && !otherData[key]) {
          otherData[key] = 1;
          this.otherTabForm.controls[key].patchValue(1, { emitEvent: false });
        }
        // Map the description fields to the correct language
        updateField(key, otherData[key]);
      });

    // Emit the changeField event if there are any changes
    if (Object.keys(newData).length) this.patchFields(newData, loading);
  }

  parseTextPrice = (price: number | string): number => {
    let parsedPrice = this.utils.validatePrice(price);
    if (parsedPrice < 0) parsedPrice = null;
    return parsedPrice;
  };

  patchFields(
    data: DeepPartial<MenuDish>,
    clearableLoaders: string[] = [],
  ): void {
    this.changeField.emit({
      dish: this.currentMenudish ?? this.dish,
      data: {
        onFulfilled: () => {
          clearableLoaders.forEach((key) => (this.loadingBars[key] = false));
        },
        ...data,
      },
    });
  }

  selectImage(id: number): void {
    this.patchFields({
      user_details: { background: id, image: null },
    });
  }

  setFormOnChange(): void {
    this.otherTabForm.reset({}, { emitEvent: false });
    Object.keys(this.otherTabForm.controls).forEach((key: string) => {
      let value;
      if (key === 'date') {
        value = this.dish[key]
          ? this.dateAdapter.parse(this.dish[key], 'yyyy-MM-dd')
          : null;
      } else if (key === 'description') {
        value = this.dish.user_details[`description_${this.lang}`];
      } else if (key === 'description_secondary') {
        value = this.dish.user_details[`description_secondary_${this.lang}`];
      } else {
        value = this.dish.user_details[key];
      }
      this.otherTabForm.patchValue({ [key]: value }, { emitEvent: false });
    });
  }

  setLoadingBar(field: string, value: boolean): string[] {
    const loading = [];
    if (this.currentMenudish.id === this.dish.id) {
      this.loadingBars[field] = value;
      if (value) loading.push(field);
    }
    return loading;
  }

  uploadImage(file: File): void {
    this.showImageSpinner = true;
    this.uploadBackgroundImage.emit({
      payload: file,
      onFulfilled: () => {
        this.showImageSpinner = false;
        this.changeDetector.markForCheck();
      },
    });
    // Temporary clearing of the deprecated background image
    if (this.dish?.user_details?.image) {
      this.changeField.emit({
        dish: this.currentMenudish ?? this.dish,
        data: {
          user_details: {
            image: null,
          },
        },
      });
    }
  }

  clearBackgroundImage(): void {
    this.changeField.emit({
      dish: this.currentMenudish ?? this.dish,
      data: {
        user_details: {
          background: null,
          image: null,
        },
      },
    });
  }

  variantsChanged(data: { item: unknown; variants: Partial<Variant>[] }): void {
    this.changeField.emit({
      data: { variants: data.variants, user_details: { price: null } },
      dish: data.item as MenuDish,
    });
    if (!data.variants.length && this.dish.dish) this.showVariants = false;
  }

  variantsCleared(item: unknown): void {
    if (!this.dish.dish) return undefined;
    this.showVariants = false;
    this.changeField.emit({
      data: { variants: [], user_details: { price: null } },
      dish: item as MenuDish,
    });
  }

  ngOnDestroy(): void {
    if (this.otherTabForm.dirty) {
      this.onValueChanged(this.otherTabForm.value);
    }
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
