import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { LockItemComponent } from '../lock-item/lock-item.component';
import { MatOptionModule } from '@angular/material/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.css'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MtTooltipDirective,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatOptionModule,
    LockItemComponent,
    TranslocoPipe,
  ],
})
export class SelectItemComponent {
  @Input() disabled = false;
  @Input() disabledFields: string[] = [];
  @Input() field: string;
  @Input() hideLabel: boolean;
  @Input() lang: InterfaceLanguage;
  @Input() list: any[];
  @Input() lockedFields: string[];
  @Input() menu: Menu;
  @Input() multiple = false;
  @Input() placeholder: string;
  @Input() showLock: boolean;
  @Input() showDefaultPlaceholder: boolean;
  @Input() translationKey: string;

  @Output() updateMenu = new EventEmitter<DeepPartial<Menu>>();

  selectChanged(event: MatSelectChange, fieldName: string) {
    const value = event.value;
    this.updateMenu.emit({
      style: {
        [fieldName]: value,
      },
    });
  }
}
