import { createAction, props } from '@ngrx/store';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Spellcheck } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import {
  BackgroundImage,
  CreatePresetFromMenu,
  FetchBackgroundImageParams,
  Menu,
  MenuAnalysis,
  MenuAnalysisType,
  MenuBackup,
} from 'src/app/shared/Models/menu';
import { Fulfillable } from 'src/app/shared/Models/models';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';

export const clearCurrentMenu = createAction(
  `[Menus container] Clear current menu`,
);

export const createFirstDishSuccess = createAction(
  `[Menus container] Create first dish success`,
);

export const completeMenuWriteTutorial = createAction(
  `[Menus container] Complete menu writing tutorial`,
);

export const createFirstMenuSuccess = createAction(
  `[Menus container] Create first menu success`,
);

export const fetchSpellcheckItem = createAction(
  `[Menus write / translation] Fetch grammar check for an item`,
  props<{ spellcheck_id: number }>(),
);

export const setCurrentSpellcheckItem = createAction(
  `[Menus write / translation] Sets the current grammar for an item locally`,
  props<{ payload: Spellcheck | null }>(),
);

export const getCurrentMenu = createAction(
  `[Menus container] Get current menu`,
  props<{ id: number; hard?: boolean }>(),
);

export const setCurrentMenu = createAction(
  `[Menus container] Set current menu`,
  props<{ menu: Menu | null }>(),
);

export const removeBackupMenu = createAction(
  `[Menus container] Remove backup menu`,
  props<{ backupId: number }>(),
);

export const addCreatedBackup = createAction(
  `[Menus container] Add created backup`,
  props<{ backup: MenuBackup }>(),
);

export const setTemplate = createAction(
  `[Menus container] Set template`,
  props<{ template }>(),
);

export const setLanguage = createAction(
  `[Menus container] Set language`,
  props<{ language }>(),
);

export const setPreset = createAction(
  `[Menus container] Set preset`,
  props<{ data: boolean }>(),
);

// FIXME: the template provided of type OnboardingTemplate is more extensive than the regular nested object
export const setOnboardingTemplateDetail = createAction(
  `[Menus container] Set onboarding template detail`,
  props<{ template: OnboardingTemplate | null }>(),
);

export const deleteOnboardingTemplate = createAction(
  `[Menus container] Delete onboarding template`,
);

export const setOriginalStyle = createAction(
  `[Menus container] Set original style`,
  props<{ id: number }>(),
);

export const addMenuTranslation = createAction(
  `[Menus container] Add menu translation`,
  props<{ lang: ContentLanguage }>(),
);

export const createMenuFromOnboardingTemplate = createAction(
  `[Menus container] Create menu from onboarding template`,
  props<{ date: string; id: number }>(),
);

export const setStyleModified = createAction(
  `[Menus Style container] Set style modified`,
  props<{ value: boolean }>(),
);

export const uploadBackgroundImage = createAction(
  `[Menus container] Upload background image`,
  props<{
    data: Fulfillable<File>;
    imageType?: number;
    params?: Partial<{ current_menu: number }>;
    callback?: (imageId: number) => void;
  }>(),
);

export const addBackgroundImage = createAction(
  `[Menus container] Add background image locally`,
  props<{ image: BackgroundImage }>(),
);

export const fetchBackgroundImages = createAction(
  `[Menus container] Fetch background images`,
  props<{ params: Partial<FetchBackgroundImageParams> }>(),
);

export const setBackgroundImages = createAction(
  `[Menus container] Set background images locally`,
  props<{ images: BackgroundImage[] }>(),
);

export const deleteBackgroundImage = createAction(
  `[Menus container] Delete background image`,
  props<{ image: BackgroundImage }>(),
);

export const removeBackgroundImage = createAction(
  `[Menus container] Remove background image locally`,
  props<{ imageId: number }>(),
);

export const createMenuBackup = createAction(
  `[Menus container] Create menu backup`,
);

export const restoreMenuBackup = createAction(
  `[Menus container] Restore menu backup`,
  props<{ id: number }>(),
);

export const afterMenuCreated = createAction(
  `[Menus container] Actions after menu created`,
  props<{ menu: Menu }>(),
);

export const deleteMenuBackup = createAction(
  `[Menus container] Delete menu backup`,
  props<{ backup: MenuBackup }>(),
);

export const modifyOrderTaking = createAction(
  `[Menus container] Modify order taking`,
  props<{ menu: Menu; state: boolean }>(),
);

export const setOrderTaking = createAction(
  `[Menus container] Set order taking locally`,
  props<{ value: boolean }>(),
);

export const patchMenu = createAction(
  `[Menus container] Patch menu`,
  props<{
    payload: DeepPartial<Menu> & { forceUpdateDishes?: boolean };
    setAsCurrent: boolean;
    callback?: Function;
    showSnack?: boolean;
  }>(),
);

export const createUpdatePreset = createAction(
  `[Menus container] Create or update preset`,
  props<{ preset: CreatePresetFromMenu; id: number; existing: boolean }>(),
);

export const saveFieldDefault = createAction(
  `[Menus container] Save field default`,
  props<{ url: string; data: { target: string; field?: string } }>(),
);

export const restoreFieldDefault = createAction(
  `[Menus container] Restore default`,
  props<{ url: string; data: { target: string; field?: string } }>(),
);

export const checkMenuGrammar = createAction(
  `[Menus container] Check grammar`,
  props<{ url: string; language: ContentLanguage; step: 1 | 3 }>(),
);

export const fetchMenuAnalysis = createAction(
  `[Menus container] Fetch menu analysis`,
  props<{
    url: string;
    lang: ContentLanguage;
    analysis_type: MenuAnalysisType;
  }>(),
);

export const setAiAnalysisLoading = createAction(
  `[Menus container] Set ai analysis loading`,
  props<{ value: boolean }>(),
);

export const downloadMenuAnalysis = createAction(
  `[Menus container] Download menu analysis`,
  props<{
    url: string;
    lang: ContentLanguage;
    analysis_type: MenuAnalysisType;
    data: MenuAnalysis;
    onFulfilled?: () => void;
  }>(),
);

export const exportMenuToExcel = createAction(
  `[Menus container] Export menu to excel`,
  props<{ menu: Menu }>(),
);
