import { BaseNameExtended } from '../Models/models';

export const days: BaseNameExtended[] = [
  {
    de: 'Montag',
    it: 'Lunedì',
    en: 'Monday',
    fr: 'Lundi',
    es: 'Lunes',
    ca: 'Dilluns',
    hr: 'Ponedjeljak',
    pl: 'Poniedziałek',
    pt: 'Segunda-feira',
    nl: 'Maandag',
    ru: 'Понедельник',
    el: 'Δευτέρα',
    da: 'Mandag',
    sv: 'Måndag',
    ar: 'الاثنين',
    eu: 'Astelehena',
    gl: 'Luns',
    et: 'Esmaspäev',
    fi: 'Maanantai',
  },
  {
    de: 'Dienstag',
    it: 'Martedì',
    en: 'Tuesday',
    fr: 'Mardi',
    es: 'Martes',
    ca: 'Dimarts',
    hr: 'Utorak',
    pl: 'Wtorek',
    pt: 'Terça-feira',
    nl: 'Dinsdag',
    ru: 'Вторник',
    el: 'Τρίτη',
    da: 'Tirsdag',
    sv: 'Tisdag',
    ar: 'يوم الثلاثاء',
    eu: 'Asteartea',
    gl: 'Martes',
    et: 'Teisipäev',
    fi: 'Tiistai',
  },
  {
    de: 'Mittwoch',
    it: 'Mercoledì',
    en: 'Wednesday',
    fr: 'Mercredi',
    es: 'Miércoles',
    ca: 'Dimecres',
    hr: 'Srijeda',
    pl: 'Środa',
    pt: 'Quarta-feira',
    nl: 'Woensdag',
    ru: 'Среда',
    el: 'Τετάρτη',
    da: 'Onsdag',
    sv: 'Onsdag',
    ar: 'الأربعاء',
    eu: 'Asteazkena',
    gl: 'Mércores',
    et: 'Kolmapäev',
    fi: 'Keskiviikko',
  },
  {
    de: 'Donnerstag',
    it: 'Giovedì',
    en: 'Thursday',
    fr: 'Jeudi',
    es: 'Jueves',
    ca: 'Dijous',
    hr: 'Četvrtak',
    pl: 'Czwartek',
    pt: 'Quinta-feira',
    nl: 'Donderdag',
    ru: 'Четверг',
    el: 'Πέμπτη',
    da: 'Torsdag',
    sv: 'Torsdag',
    ar: 'يوم الخميس',
    eu: 'Osteguna',
    gl: 'Xoves',
    et: 'Neljapäev',
    fi: 'Torstai',
  },
  {
    de: 'Freitag',
    it: 'Venerdì',
    en: 'Friday',
    fr: 'Vendredi',
    es: 'Viernes',
    ca: 'Divendres',
    hr: 'Petak',
    pl: 'Piątek',
    pt: 'Sexta-feira',
    nl: 'Vrijdag',
    ru: 'Пятница',
    el: 'Παρασκευή',
    da: 'Fredag',
    sv: 'Fredag',
    ar: 'جمعة',
    eu: 'Ostirala',
    gl: 'Venres',
    et: 'Reede',
    fi: 'Perjantai',
  },
  {
    de: 'Samstag',
    it: 'Sabato',
    en: 'Saturday',
    fr: 'Samedi',
    es: 'Sábado',
    ca: 'Dissabte',
    hr: 'Subota',
    pl: 'Sobota',
    pt: 'Sábado',
    nl: 'Zaterdag',
    ru: 'Суббота',
    el: 'Σάββατο',
    da: 'Lørdag',
    sv: 'Lördag',
    ar: 'السبت',
    eu: 'Larunbata',
    gl: 'Sábado',
    et: 'Laupäeval',
    fi: 'Lauantai',
  },
  {
    de: 'Sonntag',
    it: 'Domenica',
    en: 'Sunday',
    fr: 'Dimanche',
    es: 'Domingo',
    ca: 'Diumenge',
    hr: 'Nedjelja',
    pl: 'Niedziela',
    pt: 'Domingo',
    nl: 'Zondag',
    ru: 'Воскресенье',
    el: 'Κυριακή',
    da: 'Søndag',
    sv: 'Söndag',
    ar: 'الأحد',
    eu: 'Igandea',
    gl: 'Domingo',
    et: 'Pühapäev',
    fi: 'Sunnuntai',
  },
];
