import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { BackgroundImage, Menu } from 'src/app/shared/Models/menu';
import { Fulfillable } from 'src/app/shared/Models/models';
import { MTFont } from 'src/app/shared/Models/mtfont';
import { Separator } from 'src/app/shared/Models/separator';
import { User } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StyleMobileTabComponent } from './style-mobile-tab/style-mobile-tab.component';
import { StylePrintTabComponent } from './style-print-tab/style-print-tab.component';
import { StyleOptionsTabComponent } from './style-general-tab/style-general-tab.component';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-style-sidebar',
  templateUrl: './style-sidebar.component.html',
  styleUrls: ['./style-sidebar.component.scss'],
  standalone: true,
  imports: [
    MatTabsModule,
    StyleOptionsTabComponent,
    StylePrintTabComponent,
    StyleMobileTabComponent,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class StyleSidebarComponent implements OnChanges, OnInit {
  @Input() lang: InterfaceLanguage;
  @Input() lockedFields: string[];
  @Input() menu: Menu;
  @Input() styleModified: boolean;
  @Input() options: Separator[];
  @Input() courses: Separator[];
  @Input() mtFonts: MTFont[];
  @Input() bgImages: BackgroundImage[];
  @Input() user: User;

  @Output() showAllBackgrounds = new EventEmitter<
    'background' | 'background_coverpage'
  >();
  @Output() updateMenu = new EventEmitter<DeepPartial<Menu>>();
  @Output() uploadBackgroundImage = new EventEmitter<
    Fulfillable<File> & { field: string }
  >();
  @Output() uploadLogo = new EventEmitter<Fulfillable<File>>();

  disabledFields: string[] = [];
  blockFieldsControl = new FormControl(false);
  showLocks: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'menu' in changes &&
      this.menu &&
      (changes.menu.firstChange ||
        changes.menu.previousValue?.id !== this.menu.id)
    ) {
      this.blockFieldsControl.setValue(this.menu.block_locked_fields, {
        emitEvent: false,
      });
      this.disabledFields = this.menu.template_detail.locked_fields;
      if ([0, 1].includes(this.user?.role)) {
        this.showLocks =
          this.menu.style.locked_fields.filter(
            (f) => !['logo', 'background_iamge'].includes(f),
          ).length > 0;
      } else {
        this.disabledFields = [
          ...this.disabledFields,
          ...this.menu.style.locked_fields,
        ];
      }
    }
  }

  ngOnInit() {
    this.blockFieldsControl.valueChanges.subscribe((checked) => {
      this.updateMenu.emit({
        block_locked_fields: checked,
      });
    });
  }

  clearLocks = () => {
    this.updateMenu.emit({
      style: {
        locked_fields: [],
      },
    });
  };

  updateShowLocks = ({ checked }) => {
    this.showLocks = checked;
  };
}
