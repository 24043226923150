import {
  ContentLanguage,
  InterfaceLangsAsObject,
} from 'src/app/shared/constants/languages';
import { Location } from 'src/app/shared/Models/location';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { SimpleRule } from 'src/app/shared/Models/ruleset';
import { UserStatusPrivileges } from 'src/app/shared/Models/user';

import { InterfaceLanguage } from '../constants/languages';
import { Dish, Variant } from './dish';
import { LangButton } from './langButton';
import { MenuDish } from './menudish';
import { BaseName } from './models';
import { Separator } from './separator';
import { SimpleLayout } from './template';
import { SimpleType } from './type';
import { InterfaceLangsObject } from './type-utils';

type alignType = 'left' | 'center' | 'right';
type weightTypes = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export interface MenuPreviewData {
  url: string;
  baseLanguage?: ContentLanguage;
  langs?: LangButton[];
  numberLanguages?: number;
  multiRequired?: boolean;
  params?: any;
  hideLangs?: boolean;
  htmlPreview?: boolean;
}

export interface StyleParams {
  condensed: boolean;
  current_menu: number;
}

export class MenusOverviewParams {
  page: number;
  page_size: number;
  date_from: string;
  date_to: string;
  date_range: 'today' | 'yesterday' | 'week' | 'year';
  name: string;
  hide_location_menus: boolean;
  enable_ordertaking: boolean;
  enable_automated_orders: boolean;
  base_language: ContentLanguage;
  onboarding_template: number;
  location: number;
  location_group: number;
  created_by: number;
  condensed: boolean;
}

export class SimpleMenu {
  url: string;
  id: number;
  date: string;
  name: string;
  base_language: ContentLanguage;
  location: number;
  rule: number;
  diet: number;
  location_detail?: any;
  location_group: number;
  location_group_detail?: any;
  preview?: string;
  translations_list: ContentLanguage[];
  created_by_role?: number;
  created_by_name?: string;
  last_modified_by_name?: string;
  dishes: string;
  template: number;
  template_detail?: SimpleLayout;

  constructor(
    language: ContentLanguage = 'en',
    date: string = new Date().toISOString().slice(0, 10),
  ) {
    this.base_language = language;
    this.date = date;
  }
}

export class OverviewMenu extends SimpleMenu {
  children?: SimpleMenu[];
  date_range_min?: string;
  date_range_max?: string;
  featured?: boolean;
  master_menu?: number;
  master_menu_diet?: number;
  onboarding_template?: number;
  onboarding_template_detail?: any;
  privileges?: UserStatusPrivileges;
  rule_detail?: SimpleRule;
  type?: number;
  type_detail?: SimpleType;
  diets_list?: string[];
}

export interface ShareMenuDetail {
  locations: number[];
  copy_recipes: boolean;
  copy_diets: boolean;
}

export interface CopyMenuOptions {
  date_from: string;
  date_to: string;
  exclude_declarations: string[];
  replace_with_empty: boolean;
}
export interface CopyAsOptions {
  create_archive: boolean;
  enable_ordertaking: boolean;
}
export class Menu extends OverviewMenu {
  procurement_preview: string;
  analysis_preview?: string;
  costs_preview?: string;
  translations: string;
  dishes: string;
  user_details?: Detail;
  menudish?: MenuDish[];
  has_template_default: any[];
  has_location_default: any[];
  share_link?: string;
  backups: MenuBackup[];
  style: Style;
  style_original: number;
  style_modified: boolean;
  rule_detail: SimpleRule;
  diets: number[];

  enable_ordertaking?: boolean;
  enable_automated_orders?: boolean;
  ordertaking_layout: number;
  order_by_time: string;
  order_by_days_before: number;
  cancel_by_time: string;
  cancel_by_days_before: number;
  automated_orders_time: string;
  automated_orders_days_before: number;
  automated_orders_checkin_time: string;
  automated_orders_checkout_time: string;
  warning_lastminute_time: string;
  warning_lastminute_days_before: number;
  order_days_until: number;
  meal_sorting: number;
  maximum_components: number;
  repeated_orders_consumers: boolean;

  currency: string;
  currency_symbol: string;
  add_beverages: boolean;
  add_courses: boolean;
  add_days: boolean;
  add_dishes: boolean;
  add_sections: boolean;
  add_wines: boolean;
  add_ai: boolean;

  created_at: string;
  last_modified_at: string;
  created_by: number;
  last_modified_by: number;
  organisation: number;
  block_locked_fields: boolean;

  constructor(menu?: any, language?: ContentLanguage) {
    super(language);
    Object.assign(this, menu);
  }
}

export class MenusResults {
  count: number;
  next?: string;
  previous?: string;
  results: OverviewMenu[];
  is_loading?: boolean;
  languages?: ContentLanguage[];

  constructor(menu?) {
    Object.assign(this, menu);
  }
}

export class CreatePresetFromMenu extends BaseName {
  apply_template: boolean;
  exact: boolean;
  delete_existing?: boolean;
}

export class PresetDetail {
  preset: CreatePresetFromMenu;
  id: number;
  existing: boolean;
}
export class MenuPreset {
  id: number;
  name: string;
  url: string;
}

export type TaskType = 1 | 2 | 3 | 4 | 5;

export class GenerateArchiveOptions {
  enable_ordertaking: boolean;
  create_archive: boolean;
  task_type?: TaskType;
  ruleset?: number;
}

export class Template extends BaseName {
  image: FormData;
  create_preset: boolean;
}

export type StyleCategories = Partial<['special', 'public', 'user']>;

export interface BackgroundImage {
  id: number;
  url: string;
  type: 1 | 2;
  background_image_small: string;
  can_delete: boolean;
  created_at: Date;
  last_modified_at: Date;
  migrated: boolean;
  background_image: string;
  order: number;
  created_by: number;
  last_modified_by: number;
  location?: Location;
  organisation: number;
  partner?: number;
}

export class UpdateStyleData {
  target?: 'rule';
}
export class UpdateStyleParam {
  current_menu?: number;
}

export class Style {
  id?: number;
  category?: StyleCategories[number];
  url?: string;
  locked_fields: string[];

  course_separator: number;
  course_separator_detail: BaseNameExtended;
  option_separator: number;
  option_separator_detail: BaseNameExtended;

  style_variant: number;
  coverpage_variant: number;

  price_format: 1 | 2;
  price_format_after: boolean;
  print_price_currency: 0 | 1 | 2;
  label_format: number;
  label_position: 1 | 2;
  allergen_format: number;
  additive_format: number;
  variant_linebreak: boolean;
  declarations_linebreak: boolean;

  aggregate_courses: boolean;
  aggregate_courses_declarations: boolean;
  custom_data: any;
  highlight_sections_type: number;
  highlight_dishes_type: number;
  highlight_enddescription_type: number;
  highlight_endtext_type: number;
  highlight_variant_type: number;

  align_pagetop: alignType;
  align_section: alignType;
  align_subsection: alignType;
  align_text: alignType;
  align_detail: alignType;
  align_footnote: alignType;
  align_logo: alignType;

  capitalisation_title: number;
  capitalisation_subtitle: number;
  capitalisation_section: number;
  capitalisation_subsection: number;
  capitalisation_text: number;
  capitalisation_price: number;
  capitalisation_text_secondary: number;
  capitalisation_footnote: number;

  colour_title: string;
  colour_subtitle: string;
  colour_section: string;
  colour_subsection: string;
  colour_text: string;
  colour_price: string;
  colour_text_secondary: string;
  colour_footnote: string;

  colour_accent: string;
  colour_panel: string;
  colour_divider: string;
  colour_page_print: string;
  colour_page_mobile: string;
  colour_decorator: string;

  font_title: string;
  font_subtitle: string;
  font_section: string;
  font_subsection: string;
  font_text: string;
  font_price: string;
  font_text_secondary: string;
  font_footnote: string;

  indent_title: number;
  indent_subtitle: number;
  indent_section: number;
  indent_subsection: number;
  indent_text: number;
  indent_text_secondary: number;
  indent_footnote: number;
  indent_logo: number;
  indent_legend: number;

  image: string;
  logo: string;
  logo_small: string;
  background_detail: BackgroundImage;
  background?: number;
  background_coverpage_detail: BackgroundImage;
  background_coverpage?: number;
  background_coverpage_show: boolean;
  background_legend_show?: boolean;

  size_title: number;
  size_subtitle: number;
  size_section: number;
  size_subsection: number;
  size_text: number;
  size_price: number;
  size_text_secondary: number;
  size_footnote: number;
  size_icons: number;

  weight_title: weightTypes;
  weight_subtitle: weightTypes;
  weight_section: weightTypes;
  weight_subsection: weightTypes;
  weight_text: weightTypes;
  weight_price: weightTypes;
  weight_text_secondary: weightTypes;
  weight_footnote: weightTypes;
  weight_icons: weightTypes;

  margin_pagetop_top: number;
  margin_pagetop_bottom: number;
  margin_section_top: number;
  margin_section_bottom: number;
  margin_text: number;
  margin_description: number;

  margin_pagetop_top_mobile: number;
  margin_pagetop_bottom_mobile: number;
  margin_section_top_mobile: number;
  margin_section_bottom_mobile: number;
  margin_text_mobile: number;
  margin_description_mobile: number;

  number_columns: number;

  print_folding: number;
  print_trifold_type: number;
  print_mirror: number;
  print_duplex: boolean;
  print_backcover: boolean;
  print_reverse: boolean;

  print_allergens: boolean;
  print_additives: boolean;
  print_labels: boolean;
  print_origin: boolean;
  print_vintage: boolean;
  print_winery: boolean;

  print_coverpage: boolean;
  print_date: boolean;
  print_logo: boolean;
  print_logo_coverpage: boolean;
  print_logo_type: number;
  print_pagenumbers: boolean;
  pagenumbers_start: number;
  pagenumbers_format: number;

  print_description_dishes: boolean;
  print_description_sections: boolean;
  print_checkbox: boolean;
  print_checkbox_format: number;
  print_customnumbering: boolean;
  print_images_dishes: boolean;
  print_images_format: number;
  print_images_sections: boolean;
  images_dishes_max_height: number;
  images_dishes_max_width: number;
  images_sections_max_height: number;
  images_sections_max_width: number;
  print_article_number: boolean;
  print_numbering: boolean;
  print_nutrition_rating: boolean;
  print_nutrition_values: boolean;
  print_carbohydrate_units: boolean;
  aggregate_nutrition_values: null | 1 | 2 | 3;
  print_price_dishes: boolean;
  print_price_sections: boolean;
  print_price_dishes_inline: boolean;
  print_price_sections_inline: boolean;
  price_column_width: number;

  translate_coverpage: boolean;
  print_legend: boolean;
  print_legend_type: number;
  print_legend_all: boolean;
  print_legend_page: boolean;

  print_index: boolean;
  print_index_type: number;

  paper_format: string;
  paper_height: number;
  paper_width: number;
  paper_binding_extramargin: number;
  paper_header_height: number;
  paper_header_margin: number;
  logo_height: number;
  paper_footer_height: number;
  paper_footer_margin: number;
  paper_margins_top: number;
  paper_margins_right: number;
  paper_margins_bottom: number;
  paper_margins_left: number;
  paper_holemarks: number;
  paper_bleed: number;
  paper_crop: boolean;

  collapsible_sections: boolean;
  collapsible_subsections: boolean;
  show_in_modal: string[];
  show_product_sheets: boolean;
  show_info_url: boolean;

  default_show_nutrition_rating: boolean;
  default_show_nutrition_values: boolean;
  default_show_carbohydrate_units: boolean;
  default_show_allergens: boolean;
  default_show_labels: boolean;
  default_show_additives: boolean;
  default_show_origin: boolean;

  background_image?: string; // deprecated

  constructor() {
    Object.assign(this, InterfaceLangsAsObject);
  }
}

export interface Style extends InterfaceLangsObject {}

export type PatchStyleNameData = Partial<{
  [key in InterfaceLanguage]: string;
}>;

export class Detail {
  beforetext_ca: string;
  beforetext_de: string;
  beforetext_el: string;
  beforetext_en: string;
  beforetext_es: string;
  beforetext_fr: string;
  beforetext_hr: string;
  beforetext_it: string;
  beforetext_nl: string;
  beforetext_pl: string;
  beforetext_pt: string;
  beforetext_ru: string;
  beforetext_da: string;
  beforetext_sv: string;
  beforetext_ar: string;
  beforetext_eu: string;
  beforetext_gl: string;
  beforetext_et: string;
  beforetext_fi: string;
  coverpage_ca: string;
  coverpage_de: string;
  coverpage_el: string;
  coverpage_en: string;
  coverpage_es: string;
  coverpage_fr: string;
  coverpage_hr: string;
  coverpage_it: string;
  coverpage_nl: string;
  coverpage_pl: string;
  coverpage_pt: string;
  coverpage_ru: string;
  coverpage_da: string;
  coverpage_sv: string;
  coverpage_ar: string;
  coverpage_eu: string;
  coverpage_gl: string;
  coverpage_et: string;
  coverpage_fi: string;
  endtext_ca: string;
  endtext_de: string;
  endtext_el: string;
  endtext_en: string;
  endtext_es: string;
  endtext_fr: string;
  endtext_hr: string;
  endtext_it: string;
  endtext_nl: string;
  endtext_pl: string;
  endtext_pt: string;
  endtext_ru: string;
  endtext_da: string;
  endtext_sv: string;
  endtext_ar: string;
  endtext_eu: string;
  endtext_gl: string;
  endtext_et: string;
  endtext_fi: string;
  endtext_secondary_ca: string;
  endtext_secondary_de: string;
  endtext_secondary_el: string;
  endtext_secondary_en: string;
  endtext_secondary_es: string;
  endtext_secondary_fr: string;
  endtext_secondary_hr: string;
  endtext_secondary_it: string;
  endtext_secondary_nl: string;
  endtext_secondary_pl: string;
  endtext_secondary_pt: string;
  endtext_secondary_ru: string;
  endtext_secondary_da: string;
  endtext_secondary_sv: string;
  endtext_secondary_ar: string;
  endtext_secondary_eu: string;
  endtext_secondary_gl: string;
  endtext_secondary_et: string;
  endtext_secondary_fi: string;
  footnote_ca: string;
  footnote_de: string;
  footnote_el: string;
  footnote_en: string;
  footnote_es: string;
  footnote_fr: string;
  footnote_hr: string;
  footnote_it: string;
  footnote_nl: string;
  footnote_pl: string;
  footnote_pt: string;
  footnote_ru: string;
  footnote_da: string;
  footnote_sv: string;
  footnote_ar: string;
  footnote_eu: string;
  footnote_gl: string;
  footnote_et: string;
  footnote_fi: string;
  subtitle_ca: string;
  subtitle_de: string;
  subtitle_el: string;
  subtitle_en: string;
  subtitle_es: string;
  subtitle_fr: string;
  subtitle_hr: string;
  subtitle_it: string;
  subtitle_nl: string;
  subtitle_pl: string;
  subtitle_pt: string;
  subtitle_ru: string;
  subtitle_da: string;
  subtitle_sv: string;
  subtitle_ar: string;
  subtitle_eu: string;
  subtitle_gl: string;
  subtitle_et: string;
  subtitle_fi: string;
  title_ca: string;
  title_de: string;
  title_el: string;
  title_en: string;
  title_es: string;
  title_fr: string;
  title_hr: string;
  title_it: string;
  title_nl: string;
  title_pl: string;
  title_pt: string;
  title_ru: string;
  title_da: string;
  title_sv: string;
  title_ar: string;
  title_eu: string;
  title_gl: string;
  title_et: string;
  title_fi: string;
  wine: string;
  variants?: Variant[];
  inline_images?: any[];
  refetch?: boolean; // FIXME: this should not be here
}

export class MenuBackup {
  created_at: string;
  date: string;
  id: number;
  last_modified_at: string;
  name: string;
  preview: string;
  url: string;
  base_language: ContentLanguage;
}

export class FetchBackgroundImageParams {
  type: number;
  current_menu: number;
}

export class DownloadReportDetails {
  lang: ContentLanguage;
  menu: number;
  report: string;
}

export type CustomDataToFormGroupTypes = 'number' | 'checkbox';

export interface CustomDataToFormGroupValue {
  formControlName: string;
  order: number;
  type: CustomDataToFormGroupTypes;
  value: string | number | boolean;
  placeholder: string;
}

export interface CustomDataToFormGroup {
  [key: string]: CustomDataToFormGroupValue;
}

export interface CustomData {
  [key: string]: string | number | boolean;
}

export type SupportedCustomDataTypes = 'number' | 'boolean';

export interface SupportedCustomData {
  key: string;
  type: SupportedCustomDataTypes;
  order: number;
  locations?: number[];
}

export type ItemType = Detail | Dish | MenuDish | Separator;

export interface MenuAnalysis {
  score: string;
  justification: string;
  recommendations: string[];
}

export const MENU_ANALYSIS = [
  'meal_diversity',
  'seasonality',
  'carbon_footprint',
  'pricing',
] as const;

export type MenuAnalysisType = (typeof MENU_ANALYSIS)[number];
