<h2 mat-dialog-title>
  {{ "style.sidebar.uploads.bg-image.choose" | transloco }}:
</h2>
<mat-dialog-content>
  <div class="wrapper">
    @for (image of data.backgroundImages; track image) {
      <select-image
        [canDelete]="image.can_delete"
        [imageUrl]="image.background_image_small"
        [selected]="image.id === data.currentBackground"
        (delete)="delete(image)"
        (imgSelected)="chooseBgImage(image)"
      ></select-image>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="cancel-button" mat-button color="primary" mat-dialog-close>
    {{ "shared.buttons.done" | transloco }}
  </button>
</mat-dialog-actions>
