import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { Separator } from 'src/app/shared/Models/separator';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
} from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { KeyValuePipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { StopPropagationDirective } from '../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { GrammarCheckButtonComponent } from '../../../../shared/Components/grammar-check-button/grammar-check-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'translation-item',
  templateUrl: './translation-item.component.html',
  styleUrls: ['./translation-item.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    GrammarCheckButtonComponent,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    StopPropagationDirective,
    MatOptionModule,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class TranslationItemComponent implements OnChanges, OnDestroy, OnInit {
  private destroyed$ = new Subject<void>();
  dishType = ['dish_detail', 'separator_detail'];
  options: any[];
  selectStatus = false;

  @Input() baseLanguage: ContentLanguage;
  @Input() data: Dish | Separator;
  @Input() language: ContentLanguage;
  @Input() menudish: MenuDish;
  @Input() samedish: BaseNameExtended[];
  @Input() showLoader: 'item' | 'description' | 'other';
  @Input() showRequired: boolean;
  @Input() currentDish: MenuDish;

  @Output() setElement = new EventEmitter();
  @Output() changeTranslation = new EventEmitter<{
    item: MenuDish;
    translation: boolean;
  }>();
  @Output() changeOtherTranslation = new EventEmitter<MenuDish>();
  @Output() similarTranslation = new EventEmitter();
  @Output() similarTranslationDesc = new EventEmitter();
  @Output() getSame: EventEmitter<{
    dish: string;
    lang: ContentLanguage;
  }> = new EventEmitter();

  dishInputFocused = false;
  fields = {
    item: {
      field: '',
      name: 'translation',
      object: 'data',
      isEmpty: false,
      isAutotranslated: false,
    },
    description: {
      field: 'description_',
      name: 'description',
      object: 'data',
      isEmpty: false,
      isAutotranslated: false,
    },
    descriptionSecondary: {
      field: 'description_secondary_',
      name: 'description_secondary',
      object: 'data',
      isEmpty: false,
      isAutotranslated: false,
    },
    menudishDescription: {
      field: 'description_',
      name: 'description',
      object: 'menudish',
      isEmpty: false,
      isAutotranslated: false,
    },
    menudishDescriptionSecondary: {
      field: 'description_secondary_',
      name: 'description_secondary',
      object: 'menudish',
      isEmpty: false,
      isAutotranslated: false,
    },
  };
  rtlLangs = rtlLangs;
  showGrammarIcon: boolean;
  transControl = new FormControl<string>('');

  @ViewChild('dishInput', { static: false })
  dishInput: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.transControl.valueChanges
      .pipe(
        debounceTime(400),
        filter((v) => !!v),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((el) => {
        this.transControl.setValue(el, {
          emitEvent: false,
        });
        this.getSame.emit({
          dish: el || this.data[this.baseLanguage],
          lang: el ? this.language : this.baseLanguage,
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (`menudish` in changes && this.menudish && this.data) {
      Object.keys(this.fields).forEach((key: string) => {
        const obj = this.fields[key];
        obj.isEmpty =
          key === 'item'
            ? !this[obj.object]?.[`${obj.field}${this.language}`]
            : !this[obj.object].user_details?.[`${obj.field}${this.language}`];
        obj.isAutotranslated = this[
          obj.object
        ].user_details?.auto_translated?.includes(
          `${obj.field}${this.language}`,
        );
      });
    }
    if ('data' in changes && this.data) {
      if (this.data.user_details?.translation_disabled) {
        this.transControl.disable({ emitEvent: false });
      } else {
        this.transControl.enable({ emitEvent: false });
      }
    }
    if (
      (`data` in changes || `language` in changes) &&
      this.data &&
      this.language &&
      !this.dishInputFocused
    ) {
      this.transControl.setValue(this.data[this.language], {
        emitEvent: false,
      });

      this.showGrammarIcon = !!(
        this.data[`spellcheck_${this.language}`] && this.data[this.language]
      );
    }
    if (`currentDish` in changes && this.currentDish) {
      const details =
        this.currentDish.dish_detail || this.currentDish.separator_detail;
      const copiedText = details[this.language];
      if (
        this.menudish?.id === this.currentDish?.id &&
        this.data[this.language] !== copiedText
      ) {
        this.transControl.setValue(copiedText, { emitEvent: false });
        this.sendCopiedValue(copiedText);
      }
    }
  }

  sendCopiedValue(value) {
    const newMenuDish = _.cloneDeep(this.menudish);
    const detail = newMenuDish.dish_detail || newMenuDish.separator_detail;
    detail[this.language] = value;
    this.changeTranslation.emit({ item: newMenuDish, translation: true });
  }

  submitDish(
    { target }: FocusEvent,
    control: UntypedFormControl,
    isMenudish = false,
  ) {
    const { value, name } = <HTMLInputElement>target;
    this.dishInputFocused = false;
    const newMenudish = _.cloneDeep(this.menudish);
    const detail = newMenudish.dish_detail || newMenudish.separator_detail;
    const field = `${name}_${this.language}`;
    let previous: string;
    if (isMenudish) {
      previous = this.menudish.user_details?.[field];
      newMenudish.user_details[field] = value;
    } else if (name === 'translation') {
      previous = detail[this.language];
      detail[this.language] = value;
    } else {
      previous = detail.user_details[field];
      detail.user_details[field] = value;
    }

    if (this.selectStatus || value == null || value === previous)
      return undefined;
    if (!(control.errors && (control.dirty || control.touched))) {
      isMenudish
        ? this.changeOtherTranslation.emit(newMenudish)
        : this.changeTranslation.emit({
            item: newMenudish,
            translation: name === 'translation',
          });
    }
  }

  blurItemInput = (element) => element.blur();

  wtf(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.selectStatus = true;
  }

  selectDish(dish: any) {
    this.data[this.language] = dish[this.language];
    const newMenudish = _.cloneDeep(this.menudish);
    if (newMenudish.dish_detail) {
      newMenudish.dish_detail = this.data as Dish;
    } else {
      newMenudish.separator_detail = this.data as Separator;
    }
    this.changeTranslation.emit({ item: newMenudish, translation: true });
    setTimeout(() => (this.selectStatus = false), 1000);
    this.dishInput.nativeElement.blur();
  }

  showSimilarTranslations(
    desc = false,
    menudish = false,
    secondary = false,
    inputClick = false,
  ): void {
    if (desc) {
      this.similarTranslationDesc.emit({ menudish, secondary });
    } else {
      this.similarTranslation.emit();
    }
    this.setElement.emit(inputClick);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
