<div class="translations-actions">
  <h3>
    {{ "menus.steps.translate.title" | transloco }}
    {{ "shared.languages." + lang | transloco }}
  </h3>
  <form class="translations-search-form" #translationsForm="ngForm">
    <div class="menu-filter icon-button-density-3">
      <input
        #translationsInput
        name="transDish"
        ngControl="transDish"
        type="text"
        [ngModel]
        [class.safari]="isSafari"
        [placeholder]="
          (translations && translations['write.blocks.main.search']) ||
            'write.blocks.main.search' | transloco
        "
        (keydown.enter)="(false)"
      />
      @if (translationsInput.value) {
        <button
          class="search-clear"
          stop-propagation
          mat-icon-button
          aria-label="Clear"
          (click)="clearFilteredTranslations()"
        >
          <mat-icon class="blue">clear</mat-icon>
        </button>
      }
      <button
        class="filter-button"
        mat-icon-button
        aria-label="Search"
        (click)="translationsInput.focus()"
      >
        <mat-icon class="blue">search</mat-icon>
      </button>
    </div>
  </form>
</div>

<div class="translate-dish">
  @if (isMobileView) {
    <button
      mat-flat-button
      color="primary"
      class="add-entity-button further-actions-button"
      (click)="showMobilePanel()"
    >
      {{ "dishes.translation.further-actions" | transloco }}
      <mat-icon>arrow_right</mat-icon>
    </button>
  }
  @if (lang) {
    <content-block
      class="translations-list"
      color="primary"
      [title]="'dishes.translation.translate_to' | transloco"
      [secondTitle]="'shared.languages.' + lang | transloco"
      [translations]="true"
      [hideTitle]="filter === 2 && missingTranslationCount === 0"
      [loading]="menuDishesLoading"
    >
      @if (filter === 2 && missingTranslationCount === 0) {
        <div>
          <no-translations-screen
            [isOtherLang]="checkOtherLanguage()"
            [selectedQuote]="selectQuote()"
          >
          </no-translations-screen>
        </div>
      }
      @for (item of filteredDishes; track trackMenudishesFn($index, item)) {
        <div>
          @if (
            !(
              item?.separator_detail &&
              ["cou", "opt"].includes(item?.separator_detail.category)
            )
          ) {
            <translation-item
              class="translation-item"
              [baseLanguage]="menu?.base_language"
              [currentDish]="lastDish | copyDeep"
              [data]="item?.dish_detail || item.separator_detail | copyDeep"
              [language]="lang"
              [menudish]="item"
              [samedish]="similarTranslations$ | async"
              [showRequired]="filter === 1"
              [showLoader]="menudishUnderEdit.get(item.id)"
              (changeOtherTranslation)="changeOtherTranslation($event)"
              (changeTranslation)="changeTranslation($event)"
              (getSame)="getSame($event, item)"
              (setElement)="setElement($event)"
              (similarTranslation)="similarTranslationDish(item)"
              (similarTranslationDesc)="similarTranslationDesc(item, $event)"
            >
            </translation-item>
          }
        </div>
      }
    </content-block>
  }

  <menu-details
    class="menu-details"
    [hideEmptyLang]="menu?.base_language"
    [lang]="lang"
    [menu]="menu"
    [showVariants]="false"
    [title]="'translations.details.title' | transloco"
    (changeMenu)="patchMenu.emit($event)"
    (restoreDefault)="restoreDefault($event)"
    (saveDefault)="saveDefault($event)"
  ></menu-details>

  @if (
    showDishVariants ||
    showSectionVariants ||
    showMenudishVaraints ||
    showMenuVariants
  ) {
    <h3 class="block-title">{{ "translations.variants.title" | transloco }}</h3>
    <mat-accordion [attr.dir]="rtl ? 'rtl' : 'ltr'">
      @if (showDishVariants) {
        <mat-expansion-panel class="variants">
          <mat-expansion-panel-header
            class="not-missing"
            [class.missing]="emptyDishVariants > 0"
          >
            @if (emptyDishVariants > 0) {
              <span class="badge">{{ emptyDishVariants }}</span>
            }
            {{ "translations.variants-dishes" | transloco }}
          </mat-expansion-panel-header>
          @for (
            item of emptyVariants;
            track trackDishVariantsFn($index, item)
          ) {
            @for (
              variant of item.variants;
              track trackPriceVariantsFn($index, variant)
            ) {
              <translate-price-variant
                [baseLang]="menu?.base_language"
                [variant]="variant"
                [dish]="item.dish"
                [lang]="lang"
                (changeName)="
                  changeVariantTranslation($event, item.dish, false)
                "
              >
              </translate-price-variant>
            }
          }
        </mat-expansion-panel>
      }
      @if (showSectionVariants) {
        <mat-expansion-panel class="variants">
          <mat-expansion-panel-header
            class="not-missing"
            [class.missing]="emptySectionVariantsCount > 0"
          >
            @if (emptySectionVariantsCount > 0) {
              <span class="badge">{{ emptySectionVariantsCount }}</span>
            }
            {{ "translations.variants-sections" | transloco }}
          </mat-expansion-panel-header>
          @for (
            item of emptySectionVariants;
            track trackDishVariantsFn($index, item)
          ) {
            @for (
              variant of item.variants;
              track trackPriceVariantsFn($index, variant)
            ) {
              <translate-price-variant
                [baseLang]="menu?.base_language"
                [variant]="variant"
                [dish]="item.dish"
                [lang]="lang"
                (changeName)="
                  changeVariantTranslation($event, item.dish, false)
                "
              >
              </translate-price-variant>
            }
          }
        </mat-expansion-panel>
      }
      @if (showMenudishVaraints) {
        <mat-expansion-panel class="variants">
          <mat-expansion-panel-header
            class="not-missing"
            [class.missing]="emptyMenudishVariantsCount > 0"
          >
            @if (emptyMenudishVariantsCount > 0) {
              <span class="badge">{{ emptyMenudishVariantsCount }}</span>
            }
            {{ "translations.variants-menudishes" | transloco }}
          </mat-expansion-panel-header>
          @for (
            item of emptyMenudishVariants;
            track trackDishVariantsFn($index, item)
          ) {
            @for (
              variant of item.variants;
              track trackPriceVariantsFn($index, variant)
            ) {
              <translate-price-variant
                [baseLang]="menu?.base_language"
                [variant]="variant"
                [dish]="item.dish"
                [lang]="lang"
                (changeName)="changeVariantTranslation($event, item.dish, true)"
              >
              </translate-price-variant>
            }
          }
        </mat-expansion-panel>
      }
      @if (showMenuVariants) {
        <mat-expansion-panel class="variants">
          <mat-expansion-panel-header
            class="not-missing"
            [class.missing]="emptyMenuVariants > 0"
          >
            @if (emptyMenuVariants > 0) {
              <span class="badge">{{ emptyMenuVariants }}</span>
            }
            {{ "translations.variants-menu" | transloco }}
          </mat-expansion-panel-header>
          @for (
            variant of menu?.user_details?.variants;
            track variant;
            let i = $index
          ) {
            @if (variant[menu?.base_language]) {
              <mat-form-field class="price-input">
                <mat-label>{{ variant[menu?.base_language] }}</mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="100"
                  autocomplete="off"
                  name="tecxt"
                  #textMenuModel="ngModel"
                  [ngModel]="variant[lang]"
                  (focusout)="
                    changeMenuVariantTranslation(
                      menu?.user_details?.variants,
                      textMenuModel.viewModel,
                      i
                    )
                  "
                />
              </mat-form-field>
            }
            @for (item of textMenuModel?.errors | keys; track item) {
              <div class="input-error">
                {{
                  "write.blocks.dish-overview.information.prices.variant-price.price.errors." +
                    item.key | transloco
                }}
              </div>
            }
          }
        </mat-expansion-panel>
      }
    </mat-accordion>
  }

  @if (showContent("coverpage") && menu?.user_details) {
    <mat-slide-toggle
      #coverpageToggle
      class="add-details-header"
      color="primary"
      (change)="setCoverpageState($event)"
      [ngModel]="menu?.style?.translate_coverpage"
    >
      {{ "write.dishes.coverpage" | transloco }}
    </mat-slide-toggle>
    <mat-card class="editor" [class.hide-editor]="!coverpageToggle.checked">
      <app-quill-editor
        class="editor-component"
        [placeholder]="'write.blocks.main.editor.placeholder' | transloco"
        [contentData]="menu?.user_details['coverpage_' + lang]"
        [contentType]="'menudetail'"
        [objectId]="menu?.id"
        [lang]="lang"
        (contentChangedTap)="onQuillChangedTap()"
        (contentChanged)="onQuillEditorChanged($event)"
      >
      </app-quill-editor>
    </mat-card>
  }
</div>

<ng-template #sidePanel>
  <div class="top-right-corner">
    @if (!isMobileView) {
      <button
        mat-flat-button
        class="add-entity-button light"
        (click)="previousStep.emit()"
      >
        <mat-icon aria-hidden="true" class="arrow-icon">arrow_back</mat-icon
        >{{ "shared.buttons.back" | transloco }}
      </button>
    }
    @if (!isMobileView) {
      <button
        mat-flat-button
        color="primary"
        class="add-entity-button"
        (click)="nextStep.emit()"
      >
        {{ "shared.buttons.next" | transloco
        }}<mat-icon aria-hidden="true" class="arrow-icon"
          >arrow_forward</mat-icon
        >
      </button>
    }
  </div>

  @if (isMobileView) {
    <return-side-panel-mobile
      [text]="'translations.sidebar.go-back' | transloco"
      (click)="hideSidePanel()"
    ></return-side-panel-mobile>
  }
  @if (selectedItem) {
    <similar-translation
      #similarTranslation
      [class.similar-translation-mobile]="isMobileView"
      [baseLanguage]="menu && menu.base_language"
      [currentDish]="lastDish | copyDeep"
      [description]="getDescription()"
      [dishes]="similarTranslations$ | async"
      [dishTitle]="
        menu &&
        lastDish &&
        lastDish[lastDish?.dish_detail ? dishType[0] : dishType[1]][
          menu.base_language
        ]
      "
      [isDescription]="isDescription"
      [isLoading]="isLoading$ | async"
      [lang]="lang"
      [numberOfItems]="similarTranslationsCount$ | async"
      [spellcheckItem]="spellcheckItem"
      [tags]="wordTags"
      [user]="user$ | async"
      (changeItem)="changeTranslationDisabled(lastDish, $event)"
      (clearSelectedDish)="selectedItem = undefined; subscribeToFilters()"
      (ignoreSpellcheck)="ignoreSpellcheck()"
      (fetchMoreItems)="fetchMoreItems()"
      (fetchSpellcheck)="fetchSpellcheck.emit($event)"
      (openTranslations)="openTranslations($event)"
      (similar)="selectSimilar($event)"
      (updateSimilar)="updateSimilarTranslation($event)"
    >
    </similar-translation>
  } @else {
    <div class="sidebar-intro">
      {{ "translations.sidebar.intro" | transloco }}
    </div>
    <div class="filters-wrapper">
      <express-translation
        class="express-translate menu-action"
        [isTrial]="isTrial"
        [profileComplete]="profileComplete"
        [loading]="menuDishesLoading"
        [disabled]="blockExpressTranslationLangs.includes(lang)"
        (expressTranslate)="expressTranslate()"
      ></express-translation>
      <magic-stick
        class="grammar-check menu-action"
        [loading]="menuDishesLoading"
        [disabled]="blockGrammarCheckLangs.includes(lang)"
        [title]="'dishes.translation.grammar-check.title' | transloco"
        [description]="
          'dishes.translation.grammar-check.description' | transloco
        "
        [buttonMsg]="'dishes.translation.grammar-check.button' | transloco"
        [buttonLoadingMsg]="
          'dishes.translation.grammar-check.button-loading' | transloco
        "
        (clicked)="
          isTrial && !profileComplete ? showTrialBanner() : grammarCheck()
        "
      ></magic-stick>
      <mat-expansion-panel
        [class.faded]="menuDishesLoading"
        class="filters"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="filters-header">
          <mat-panel-title>
            <span class="title">
              {{ "dishes.translation.filter.title" | transloco }}
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="filter-items">
          <div class="title-filter">
            <div class="filter">
              <mat-form-field>
                <mat-select
                  #matSelect
                  (selectionChange)="filterChanged($event)"
                  [ngModel]="filter"
                >
                  @for (filter of dishFilters; track filter) {
                    <mat-option [value]="filter.id">
                      {{ filter.value | transloco }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <form class="search-form" [formGroup]="searchForm">
            <div class="type">
              <div class="title type-title">
                {{ "dishes.translation.search-type" | transloco }}
              </div>
              <mat-form-field [color]="'primary'" class="type-field">
                <mat-select formControlName="filter">
                  <mat-option [value]="'default'">
                    {{ "dishes.translation.all" | transloco }}
                  </mat-option>
                  @for (filter of filters; track filter) {
                    <mat-option [value]="filter">
                      {{ "dishes." + filter + ".placeholder" | transloco }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </form>
          <div class="title-filter">
            <div class="filter">
              <div class="translations-count-detail">
                <div class="label">
                  {{
                    "dishes.translation.filter.missing-translations" | transloco
                  }}
                </div>
                <div
                  class="translation-count missing-count"
                  [class.missing]="missingTranslationCount"
                >
                  {{ missingTranslationCount }}
                </div>
              </div>
            </div>
          </div>
          <div class="title-filter">
            <div class="filter">
              <div class="translations-count-detail">
                <div class="label">
                  {{
                    "dishes.translation.filter.unverified-express-translations"
                      | transloco
                  }}
                </div>
                <div
                  class="translation-count unverified-count"
                  [class.missing]="unverifiedTranslationsCount"
                >
                  {{ unverifiedTranslationsCount }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  }
</ng-template>
