import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgForm,
  NgModel,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { SidePanelControllerBase } from 'src/app/shared/Classes/side-panel-controller.base';
import { Categories } from 'src/app/shared/constants/categories';
import {
  blockExpressTranslationLangs,
  blockGrammarCheckLangs,
  ContentLanguage,
  rtlLangs,
} from 'src/app/shared/constants/languages';
import { Dish, Spellcheck, Variant } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { Separator } from 'src/app/shared/Models/separator';
import {
  SearchForm,
  SimilarTypes,
  Translation,
} from 'src/app/shared/Models/translation';
import {
  clearSimilarDishes,
  fetchDishesAutocomplete,
  fetchSeparatorAutocomplete,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { openGoogleTranslate } from 'src/app/shared/utils.functions';
import { cloneDeep, identity, isObject, pick, pickBy, uniqBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip } from 'rxjs/operators';

import {
  checkMenuGrammar,
  restoreFieldDefault,
  saveFieldDefault,
} from '../ngrx/menu-edit.actions';
import {
  fetchMenuDishesFull,
  updateDish,
  updateSeparator,
} from '../write/ngrx/menu-write.actions';
import { MenuService } from './../../menu.service';
import { updateMenuDish } from './../write/ngrx/menu-write.actions';
import {
  expressTranslateMenu,
  fetchMenuTranslations,
  fetchMoreSimilarTranslations,
  fetchSimilarTranslations,
  setCurrentTranslations,
  setTranslationToMenuDish,
} from './ngrx/menu-translate.actions';
import {
  isCurrentTranslationLoading,
  selectCurrentTranslation,
  selectSimilarTranslations,
  selectSimilarTranslationsCount,
  selectTranslationsList,
} from './ngrx/menu-translate.selectors';
import {
  TranslatePriceVariant,
  TranslatePriceVariantComponent,
} from './translate-price-variant/translate-price-variant.component';
import { CopyDeepPipe } from '../../../shared/Pipes/copy-deep.pipe';
import { KeysPipe } from '../../../shared/Pipes/keys.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MagicStickComponent } from '../../../shared/Components/magic-stick/magic-stick.component';
import { ExpressTranslationComponent } from '../../../shared/Components/express-translation/express-translation.component';
import { SimilarTranslationComponent } from './similar-translation/similar-translation.component';
import { ReturnSidePanelMobileComponent } from '../../../shared/Components/return-side-panel-mobile/return-side-panel-mobile.component';
import { QuillEditorComponent } from '../../../shared/Components/quill-editor/quill-editor.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MenuDetailsComponent } from '../write/menu-details/menu-details.component';
import { TranslationItemComponent } from './translation-item/translation-item.component';
import { NoTranslationsScreenComponent } from './no-translations-screen/no-translations-screen.component';
import { ContentBlockComponent } from '../../../shared/Components/content-block/content-block.component';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';

export enum TranslationFilters {
  SEE_ALL = 0,
  SEE_ALL_REQUIRED = 1,
  SEE_MISSING_ONLY = 2,
  SEE_AUTO_TRANSLATED = 3,
}

@Component({
  selector: 'translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    StopPropagationDirective,
    MatIconModule,
    ContentBlockComponent,
    NoTranslationsScreenComponent,
    TranslationItemComponent,
    MenuDetailsComponent,
    MatExpansionModule,
    TranslatePriceVariantComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatCardModule,
    QuillEditorComponent,
    ReturnSidePanelMobileComponent,
    SimilarTranslationComponent,
    ExpressTranslationComponent,
    MagicStickComponent,
    MatSelectModule,
    MatOptionModule,
    AsyncPipe,
    TranslocoPipe,
    KeysPipe,
    CopyDeepPipe,
  ],
})
export class TranslationsComponent
  extends SidePanelControllerBase
  implements OnChanges, OnDestroy, OnInit, AfterViewInit
{
  @Input() menuDishes: MenuDish[];
  @Input() menuDishesLoading: boolean;
  @Input() isTrial: boolean;
  @Input() lang: ContentLanguage;
  @Input() menu: Menu;
  @Input() profileComplete: boolean;
  @Input() spellcheckItem: Spellcheck;
  @Input() translations: object;

  @Output() expressTranslateEvent = new EventEmitter<{
    menuId: number;
    menuTranslationId: number;
  }>();
  @Output() fetchSpellcheck = new EventEmitter<number>();
  @Output() nextStep = new EventEmitter();
  @Output() patchMenu = new EventEmitter<DeepPartial<Menu>>();
  @Output() previousStep = new EventEmitter();

  currentDish$ = this.ngrxStore.select(selectCurrentTranslation);
  isLoading$ = this.ngrxStore.select(isCurrentTranslationLoading);
  similarTranslationsCount$ = this.ngrxStore.select(
    selectSimilarTranslationsCount,
  );
  similarTranslations$ = this.ngrxStore.select(selectSimilarTranslations);
  translationsList$ = this.ngrxStore.select(selectTranslationsList);
  user$ = this.ngrxStore.select(selectUser);

  @ViewChild('textMenuModel')
  textMenuModel: NgModel;

  blockExpressTranslationLangs = blockExpressTranslationLangs;
  blockGrammarCheckLangs = blockGrammarCheckLangs;
  dishFilters = [
    {
      id: TranslationFilters.SEE_ALL,
      value: 'shared.buttons.see-all',
    },
    {
      id: TranslationFilters.SEE_ALL_REQUIRED,
      value: 'dishes.translation.filter.required',
    },
    {
      id: TranslationFilters.SEE_MISSING_ONLY,
      value: 'dishes.translation.filter.missing',
    },
    {
      id: TranslationFilters.SEE_AUTO_TRANSLATED,
      value: 'dishes.translation.filter.auto-translated',
    },
  ];
  dishType = ['dish_detail', 'separator_detail'];
  filter = TranslationFilters.SEE_ALL_REQUIRED;
  emptyDishVariants = 0;
  emptyMenudishVariants: TranslatePriceVariant[] = [];
  emptyMenudishVariantsCount = 0;
  emptyMenuVariants = 0;
  unverifiedTranslationsCount = 0;
  missingTranslationCount = 0;
  emptySectionVariants: TranslatePriceVariant[] = [];
  emptySectionVariantsCount = 0;
  emptyVariants: TranslatePriceVariant[] = [];
  filteredDishes: MenuDish[];
  filters = [Categories.DISH, Categories.BEVERAGE, Categories.WINE];
  filtersSubscription: Subscription;
  isDescription = false;
  isMobileView: boolean;
  isOther = false;
  isSecondary = false;
  isSafari = false;
  form = new SearchForm();
  lastDish: MenuDish;
  minPrice = {
    EUR: '1500',
    CAD: '2500',
    GBP: '1500',
    USD: '2000',
    AUD: '2700',
    NZD: '3000',
  };
  searchForm: UntypedFormGroup;
  selectedItem: MenuDish;
  showDishVariants = false;
  showMenuVariants = false;
  showMenudishVaraints = false;
  showSectionVariants = false;
  wordTags: { value: string; selected: boolean }[] = [];
  translationsList: Translation[];
  transSearchParams: { [key: string]: string } = {};
  transSubscription: Subscription;
  rtl = false;
  showLoader;
  menudishUnderEdit = new Map<number, 'item' | 'description' | 'other'>();

  @ViewChild(`matSelect`) matSelect: MatSelect;
  private _translationsForm: NgForm;
  @ViewChild('translationsForm')
  set translationsForm(content: NgForm) {
    if (this._translationsForm !== content) {
      this._translationsForm = content;
    }
    this.subscribeToTranslationsForm();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private menuService: MenuService,
    private utils: UtilsService,
    protected ngrxStore: Store<State>,
    protected overlayService: OverlayService,
  ) {
    super(ngrxStore, overlayService);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.handleQueryParams(params);
    });
    this.translationsList$.subscribe((v) => {
      this.translationsList = v;
    });
    this.isSafari = this.utils.isSafari();
    this.currentDish$.subscribe((v) => {
      this.lastDish = v ? v : this.lastDish;
    });
    this.buildForm();
  }

  ngAfterViewInit() {
    this.matSelect && this.matSelect.writeValue(this.filter);
    if (this.sidePanel)
      this.sidePanel.mobileViewSubject.subscribe((value) => {
        setTimeout(() => {
          this.isMobileView = value;
        });
        if (value) {
          this.ngrxStore.dispatch(
            setCurrentTranslations({ menuDish: undefined }),
          );
        }
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngrxStore.dispatch(fetchMenuDishesFull({}));
    this.subscribeToFilters();
  }

  ngOnChanges(changes: any) {
    if ('lang' in changes && this.lang) {
      this.rtl = rtlLangs.includes(this.lang);
    }
    if ('menu' in changes && !this.translationsList.length && this.menu) {
      this.ngrxStore.dispatch(fetchMenuTranslations({ menu: this.menu }));
    }
    if (
      this.menuDishes &&
      (!this.filteredDishes?.length ||
        'menuDishes' in changes ||
        'lang' in changes)
    ) {
      this.filterHandle(this.menuDishes, this.filter);
      this.getPriceVariants(this.menuDishes);
    }
    this.showDishVariants = false;
    if (this.menuDishes) {
      this.menuDishes.forEach((dish) => {
        if (dish.dish && dish.dish_detail?.variants) {
          dish.dish_detail.variants.forEach((variant) => {
            if (variant[this.menu.base_language]) {
              this.showDishVariants = true;
            }
          });
        }
      });
    }
    this.showSectionVariants = false;
    if (this.menuDishes) {
      this.menuDishes.forEach((dish) => {
        if (dish.separator && dish.separator_detail?.variants) {
          dish.separator_detail.variants.forEach((variant) => {
            if (variant[this.menu.base_language]) {
              this.showSectionVariants = true;
            }
          });
        }
      });
    }
    this.showMenudishVaraints = false;
    if (this.menuDishes) {
      this.menuDishes.forEach((dish) => {
        dish.variants?.forEach((variant) => {
          if (variant[this.menu.base_language]) {
            this.showMenudishVaraints = true;
          }
        });
      });
    }
    this.showMenuVariants = false;
    if (this.menu?.user_details) {
      this.emptyMenuVariants = this.menu.user_details.variants.filter(
        (variant) => !variant[this.lang] && variant[this.menu.base_language],
      ).length;
      this.showMenuVariants =
        this.emptyMenuVariants > 0 ||
        this.menu.user_details.variants.filter(
          (variant) => variant[this.menu.base_language],
        ).length > 0;
    }
    this.countUnverifiedAndMissingTranslations();
  }

  searchTranslations(params) {
    if (params === undefined) return undefined;
    if (isObject(params)) {
      this.transSearchParams = { ...this.transSearchParams, ...params };
      this.transSearchParams = pick(
        this.transSearchParams,
        Object.keys(params),
      );
    } else if (params) {
      this.transSearchParams = {
        ...this.transSearchParams,
        search: `${this.lang}-${this.menu.base_language}+${params}`,
      };
    } else {
      this.transSearchParams = { ...this.transSearchParams };
    }
    this.ngrxStore.dispatch(
      fetchMenuDishesFull({ params: this.transSearchParams }),
    );
  }

  checkOtherLanguage() {
    return !['en', 'de', 'fr', 'it', 'es'].includes(this.lang);
  }

  selectQuote() {
    switch (this.lang) {
      case 'fr':
        return ` "La gastronomie est l'art d'utiliser la nourriture pour créer le bonheur.” — Theodore Zeldin `;
      case 'es':
        return ` "La historia de la gastronomía es la historia del mundo." — Chef Carme Ruscalleda `;
      case 'en':
        return ` "To eat is a necessity, but to eat intelligently is an art." — François de la Rochefoucauld `;
      case 'de':
        return ` "Eine gute Küche ist das Fundament allen Glücks." — Georges Auguste Escoffier `;
      case 'it':
        return ` "Per me non c'è un grande chef senza una grande squadra." — Daniel Boulud `;
      default:
        return '';
    }
  }

  subscribeToTranslationsForm() {
    if (!this._translationsForm) return undefined;
    if (this.transSubscription) this.transSubscription.unsubscribe();
    this.transSubscription = this._translationsForm.control.valueChanges
      .pipe(
        debounceTime(400),
        map(({ transDish }) => transDish),
      )
      .subscribe((v) => {
        this.searchTranslations(v);
      });
  }

  clearFilteredTranslations() {
    this._translationsForm.reset({});
    delete this.transSearchParams.search;
    this.searchTranslations({});
  }

  handleQueryParams(params: Params) {
    const { sort } = params || {};
    if (sort) {
      this.filter = +(<TranslationFilters>sort);
      this.filterHandle(this.menuDishes, this.filter);
    }
  }

  expressTranslate() {
    if (!this.translationsList) return undefined;
    const trans = this.translationsList.find((t) => t.language === this.lang);
    if (!trans) return undefined;
    this.ngrxStore.dispatch(
      expressTranslateMenu({
        menuId: this.menu.id,
        menuTranslationId: trans.id,
        params: this.transSearchParams,
      }),
    );
  }

  grammarCheck(): void {
    this.ngrxStore.dispatch(
      checkMenuGrammar({ url: this.menu.url, language: this.lang, step: 3 }),
    );
  }

  buildForm(): void {
    this.searchForm = this.fb.group({
      filter: ['default', [Validators.minLength(2)]],
    });
  }

  subscribeToFilters(): void {
    this.filtersSubscription?.unsubscribe();
    this.filtersSubscription = this.searchForm.valueChanges
      .pipe(skip(1), distinctUntilChanged(), debounceTime(400))
      .subscribe((data: SearchForm) => {
        const params = {};
        if (data.filter === 'default') {
          params[`category`] = undefined;
        } else {
          params[`category`] = data.filter;
        }
        if (data.search) params[this.lang] = data.search;
        if (this.filter) params[`${this.lang}_isempty`] = true;
        this.searchAndFilter(params);
      });
  }

  showContent(fieldName: string): boolean {
    return !!(
      this.menu.user_details?.[`${fieldName}_${this.menu.base_language}`] &&
      this.lang
    );
  }

  searchAndFilter(params: any) {
    params = pickBy(params, identity);
    this.searchTranslations(params);
  }

  setElement(inputClick) {
    if (!this.isMobileView || !inputClick) {
      this.showSidePanel();
    }
  }

  showMobilePanel() {
    this.selectedItem = null;
    this.showSidePanel();
  }

  updateItem(
    menuDish: MenuDish,
    data?: DeepPartial<Dish> | DeepPartial<Separator>,
    onFulfilled?: (md) => void,
  ) {
    if (menuDish.dish_detail) {
      this.ngrxStore.dispatch(
        updateDish({
          url: menuDish.dish_detail.url,
          id: menuDish.dish_detail.id,
          dish: (data as DeepPartial<Dish>) ?? menuDish.dish_detail,
          menuDish,
          params: { edit: true },
          onFulfilled,
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        updateSeparator({
          url: menuDish.separator_detail.url,
          id: menuDish.separator_detail.id,
          separator:
            (data as DeepPartial<Separator>) ?? menuDish.separator_detail,
          menuDish,
          params: { edit: true },
          onFulfilled,
        }),
      );
    }
  }

  changeTranslationDisabled = (dish: MenuDish, state?: boolean) => {
    if (!dish) return undefined;
    this.menudishUnderEdit.set(dish.id, 'item');
    const onFulfilled = (md: MenuDish) => {
      this.menudishUnderEdit.delete(md.id);
      if (state) this.similarTranslationDish(md);
    };
    this.updateItem(
      dish,
      { user_details: { translation_disabled: state } },
      onFulfilled,
    );
  };

  changeMenuVariantTranslation(
    variants: Variant[],
    value: string,
    index: number,
  ): void {
    const newVariants = [...variants];
    newVariants[index] = { ...newVariants[index], [this.lang]: value };
    this.patchMenu.emit({ user_details: { variants: newVariants } });
  }

  changeTranslation = ({
    item,
    translation,
  }: {
    item: MenuDish;
    translation: boolean;
  }) => {
    this.menudishUnderEdit.set(item.id, translation ? 'item' : 'description');
    const onFulfilled = (md) => {
      this.menudishUnderEdit.delete(md.id);
    };
    this.updateItem(item, undefined, onFulfilled);
  };

  ignoreSpellcheck(): void {
    if (!this.lastDish) return undefined;
    const currentMenuDish = cloneDeep(this.lastDish);
    const onFulfilled = (md: MenuDish) => {
      this.utils.getTranslation(
        'menus.shared.grammar-ignored',
        (message: string) => {
          this.ngrxStore.dispatch(showSnackbarMessage({ message }));
        },
      );
    };
    if (currentMenuDish.dish_detail) {
      this.ngrxStore.dispatch(
        updateDish({
          url: currentMenuDish.dish_detail.url,
          id: currentMenuDish.dish_detail.id,
          dish: { spellcheck_ignore: true },
          menuDish: currentMenuDish,
          onFulfilled,
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        updateSeparator({
          url: currentMenuDish.separator_detail.url,
          id: currentMenuDish.separator_detail.id,
          separator: { spellcheck_ignore: true },
          menuDish: currentMenuDish,
          onFulfilled,
        }),
      );
    }
  }

  changeOtherTranslation = (dish: MenuDish) => {
    this.menudishUnderEdit.set(dish.id, 'other');
    const onFulfilled = (md: MenuDish) => {
      this.menudishUnderEdit.delete(dish.id);
    };
    this.ngrxStore.dispatch(
      updateMenuDish({
        url: dish.url,
        id: dish.id,
        menuDish: dish,
        onFulfilled,
      }),
    );
  };

  private getTargetObj = (menuDish: MenuDish, isOther = false) =>
    isOther ? menuDish : menuDish.separator_detail || menuDish.dish_detail;

  changeVariantTranslation = (
    updatedVariant: Variant,
    menuDish: MenuDish,
    isOther: boolean,
  ) => {
    const targetObj = this.getTargetObj(menuDish, isOther);
    const updatedVariants = targetObj.variants.map((variant: Variant) =>
      variant.id === updatedVariant.id ? { ...updatedVariant } : { ...variant },
    );
    if (isOther) {
      this.ngrxStore.dispatch(
        updateMenuDish({
          url: menuDish.url,
          id: menuDish.id,
          menuDish: { variants: updatedVariants },
        }),
      );
    } else if (menuDish.dish_detail) {
      this.ngrxStore.dispatch(
        updateDish({
          url: menuDish.dish_detail.url,
          id: menuDish.dish_detail.id,
          menuDish,
          dish: { variants: updatedVariants },
          params: { edit: true },
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        updateSeparator({
          url: menuDish.separator_detail.url,
          id: menuDish.separator_detail.id,
          menuDish,
          separator: { variants: updatedVariants },
          params: { edit: true },
        }),
      );
    }
  };

  openTranslations = (data: MenuDish): void => {
    openGoogleTranslate(
      data,
      this.menu.base_language,
      this.lang,
      this.isDescription,
      this.isOther,
      this.isSecondary,
    );
  };

  similarTranslationDish(item: MenuDish, ignoreTags?: boolean) {
    if (!item) return undefined;
    this.selectedItem = item;
    this.ngrxStore.dispatch(setCurrentTranslations({ menuDish: item }));
    this.isDescription = false;
    this.isOther = false;
    this.isSecondary = false;
    const dish = item.dish_detail || item.separator_detail;

    if (
      (item.dish_detail || item.separator_detail)?.user_details
        ?.translation_disabled
    )
      return undefined;
    if (!ignoreTags) {
      this.wordTags = dish[this.menu.base_language]
        .split(' ')
        .map((part) => ({ value: part.replace(/\.|,/, ''), selected: true }));
    }
    this.fetchSimilarTranslation(item, this.getSimilarName());
  }

  similarTranslationDesc(
    item: MenuDish,
    event: { menudish: boolean; secondary: boolean },
    ignoreTags?: boolean,
  ) {
    if (!item) return undefined;
    const { menudish, secondary } = event;
    this.selectedItem = item;
    this.isDescription = true;
    this.isOther = menudish;
    this.isSecondary = secondary;
    const isSeparator = !item.dish_detail;
    const dish = menudish
      ? item
      : isSeparator
        ? item.separator_detail
        : item.dish_detail;
    if (!ignoreTags) {
      this.wordTags = dish.user_details[
        `description_${secondary ? 'secondary_' : ''}${this.menu.base_language}`
      ]
        .split(' ')
        .map((part) => ({ value: part.replace(/\.|,/, ''), selected: true }));
    }
    this.ngrxStore.dispatch(setCurrentTranslations({ menuDish: item }));
    this.fetchSimilarTranslationDesc(
      this.getSimilarName(),
      this.wordTags.some((t) => !t.selected),
    );
  }

  updateSimilarTranslation(item: MenuDish) {
    if (!item) return undefined;
    if (this.isDescription) {
      this.similarTranslationDesc(
        item,
        { menudish: this.isOther, secondary: this.isSecondary },
        true,
      );
    } else {
      this.similarTranslationDish(item, true);
    }
  }

  fetchSimilarTranslation(item: MenuDish, dishName: string) {
    const type = item[this.dishType[0]]
      ? SimilarTypes.DISH
      : SimilarTypes.SEPARATOR;
    this.ngrxStore.dispatch(
      fetchSimilarTranslations({
        name: dishName,
        translationType: type,
        lang: this.lang,
        currentLang: this.menu.base_language,
      }),
    );
  }

  fetchSimilarTranslationDesc(dishName: string, filtered: boolean) {
    this.ngrxStore.dispatch(
      fetchSimilarTranslations({
        name: dishName,
        translationType: filtered
          ? SimilarTypes.FILTERED_DESC
          : SimilarTypes.DESCRIPTION,
        lang: this.lang,
        currentLang: this.menu.base_language,
      }),
    );
  }

  getSimilarName = () =>
    this.wordTags
      .filter((tag) => tag.selected)
      .map((tag) => tag.value)
      .join('+');

  getPriceVariants(dishes: MenuDish[]): void {
    this.emptyVariants = uniqBy(
      dishes.filter((dish) => dish.dish_detail),
      (dish) => dish.dish_detail.id,
    )
      .map((dish) => {
        dish.dish_detail.variants?.forEach((variant) => {
          if (!variant[this.lang]) {
            this.emptyDishVariants++;
          }
        });
        return {
          dish,
          variants: dish.dish_detail.variants,
        };
      })
      .filter((v) => v.variants && v.variants.length);
    this.emptyMenudishVariants = uniqBy(dishes, (dish) => dish?.id)
      .map((dish) => {
        dish.variants?.forEach((variant) => {
          if (!variant[this.lang]) {
            this.emptyMenudishVariantsCount++;
          }
        });
        return {
          dish,
          variants: dish.variants,
        };
      })
      .filter((v) => v.variants && v.variants.length);
    this.emptySectionVariants = uniqBy(
      dishes.filter(
        (dish) =>
          dish.separator_detail &&
          ![`cou`, `opt`].includes(dish?.separator_detail?.category),
      ),
      (dish) => dish.separator_detail.id,
    )
      .map((dish) => {
        dish.separator_detail.variants?.forEach((variant) => {
          if (!variant[this.lang]) {
            this.emptySectionVariantsCount++;
          }
        });
        return {
          dish,
          variants: dish.separator_detail.variants,
        };
      })
      .filter((v) => v.variants && v.variants.length);
  }

  selectSimilar(dish: BaseNameExtended) {
    const append = this.wordTags.some((tag) => !tag.selected);
    this.ngrxStore.dispatch(
      setTranslationToMenuDish({
        dish,
        lang: this.lang,
        append,
        isDescription: this.isDescription,
        isOther: this.isOther,
        isSecondary: this.isSecondary,
      }),
    );
  }

  getSame = ({ dish, lang }, current) => {
    current[this.dishType[0]]
      ? this.ngrxStore.dispatch(
          fetchDishesAutocomplete({
            params: {
              [lang]: dish,
              category: current.dish_detail.category,
              current_menu: this.menu.id,
            },
          }),
        )
      : this.ngrxStore.dispatch(
          fetchSeparatorAutocomplete({
            params: {
              current_menu: this.menu.id,
              [lang]: dish,
            },
          }),
        );
  };

  filterHandle(dishes: MenuDish[], filter: TranslationFilters) {
    if (!dishes) return undefined;
    this.emptyDishVariants = 0;
    this.emptyMenudishVariantsCount = 0;
    this.emptySectionVariantsCount = 0;
    this.filteredDishes = dishes
      .filter(
        (item) =>
          (item.dish_detail || item.separator_detail).category !== 'cou' &&
          (item.dish_detail || item.separator_detail).category !== 'opt',
      )
      .filter((dish) => {
        switch (filter) {
          case TranslationFilters.SEE_ALL:
            return true;
          case TranslationFilters.SEE_MISSING_ONLY:
            return this.filterMissing(dish);
          case TranslationFilters.SEE_ALL_REQUIRED:
            return this.filterRequired(dish);
          case TranslationFilters.SEE_AUTO_TRANSLATED:
            return this.filterAutoTranslated(dish);
          default:
            return true;
        }
      })
      .filter(
        (item) =>
          (item.dish_detail || item.separator_detail)[this.lang] !== ' ',
      );
  }

  filterMissing(item: MenuDish): boolean {
    const details = item.dish_detail || item.separator_detail;
    return (
      !details[this.lang] ||
      (item.user_details[this.menu.base_language] &&
        !item.user_details[this.lang]) ||
      (item.user_details['description_' + this.menu.base_language] &&
        !item.user_details['description_' + this.lang]) ||
      (details.user_details['description_' + this.menu.base_language] &&
        !details.user_details['description_' + this.lang]) ||
      (item.user_details['description_secondary_' + this.menu.base_language] &&
        !item.user_details['description_secondary_' + this.lang]) ||
      (details.user_details[
        'description_secondary_' + this.menu.base_language
      ] &&
        !details.user_details['description_secondary_' + this.lang])
    );
  }

  filterRequired(item: MenuDish): boolean {
    const details = item.dish_detail || item.separator_detail;
    return (
      !details?.user_details?.translation_disabled ||
      item.user_details['description_' + this.menu.base_language] ||
      details.user_details['description_' + this.menu.base_language] ||
      item.user_details['description_secondary_' + this.menu.base_language] ||
      details.user_details['description_secondary_' + this.menu.base_language]
    );
  }

  filterAutoTranslated(item: MenuDish): boolean {
    const details = item.dish_detail || item.separator_detail;
    const autoTranslated = details.user_details?.auto_translated?.includes(
      `${this.lang}`,
    );
    return autoTranslated ? true : false;
  }

  countUnverifiedAndMissingTranslations() {
    this.unverifiedTranslationsCount = 0;
    this.missingTranslationCount = 0;
    this.menuDishes
      .filter(
        (item) =>
          !(
            item.separator_detail &&
            ['cou', 'opt'].includes(item.separator_detail.category)
          ),
      )
      .forEach((dish) => {
        if (this.filterMissing(dish)) {
          this.missingTranslationCount += 1;
        }
        if (this.filterAutoTranslated(dish)) {
          this.unverifiedTranslationsCount += 1;
        }
      });
  }

  fetchMoreItems() {
    this.ngrxStore.dispatch(fetchMoreSimilarTranslations());
  }

  getDescription() {
    const detail = this.isOther
      ? this.lastDish
      : this.lastDish?.dish_detail || this.lastDish?.separator_detail;
    return detail?.user_details?.[
      `description_${this.isSecondary ? 'secondary_' : ''}${
        this.menu.base_language
      }`
    ];
  }

  setCoverpageState(state: MatSlideToggleChange) {
    this.patchMenu.emit({
      style: { translate_coverpage: state.checked } as any,
    });
  }

  filterChanged(selected: MatSelectChange) {
    this.filter = selected.value;
    this.filterHandle(this.menuDishes, this.filter);
  }

  trackMenudishesFn(index, dish): number {
    return dish.id;
  }

  trackPriceVariantsFn(index, item: Variant): number {
    return item.id;
  }

  trackDishVariantsFn(index, item: TranslatePriceVariant): number {
    return item.dish.id;
  }

  onQuillChangedTap(): void {
    // this.menuService.processMenuCoverpage(this.menu, this.lang);
  }

  onQuillEditorChanged(data: string): void {
    this.menuService.processMenuOnQuillChanged(
      this.menu,
      this.lang,
      data,
      (res) => this.patchMenu.emit({ user_details: res }),
    );
  }

  saveDefault(target: string | any, field?: string) {
    if (typeof target !== 'string') {
      field = target.field;
      target = target.target;
    }
    const data = { target, field };
    this.ngrxStore.dispatch(saveFieldDefault({ url: this.menu.url, data }));
  }

  restoreDefault(target: string | any, field?: string) {
    if (typeof target !== 'string') {
      field = target.field;
      target = target.target;
    }
    const data = { target, field };
    this.ngrxStore.dispatch(restoreFieldDefault({ url: this.menu.url, data }));
  }

  showTrialBanner() {
    this.utils.showTrialBlockedBox();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.filtersSubscription?.unsubscribe();
    this.transSubscription?.unsubscribe();
    this.ngrxStore.dispatch(setCurrentTranslations({ menuDish: null }));
    this.ngrxStore.dispatch(clearSimilarDishes());
  }
}
