"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var GoogleTagManager = function (_a) {
    var _b = (_a === void 0 ? {} : _a).dataLayerName, dataLayerName = _b === void 0 ? 'dataLayer' : _b;
    return function (events) {
        if (typeof window === 'undefined') {
            return;
        }
        if (!window[dataLayerName] ||
            typeof window[dataLayerName].push !== 'function') {
            throw new Error("redux-beacon error: window." + dataLayerName + " is not defined. Have you forgotten to include Google Tag Manager and dataLayer?");
        }
        events.forEach(function (event) {
            if (typeof event === 'object') {
                window[dataLayerName].push(utils_1.convertFromGoogleAnalyticsEventIfNeeded(event));
            }
        });
    };
};
exports.default = GoogleTagManager;
