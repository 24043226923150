import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { InterfaceLanguage } from '../constants/languages';
import { GeneralFormErrors } from '../Models/authentication';
import { Additive, Allergen, Label } from '../Models/declarations';
import { Diet } from '../Models/diet';
import { CondensedDish, Dish } from '../Models/dish';
import { Ingredient } from '../Models/ingredients';
import { LocationGroup, SimpleLocation } from '../Models/location';
import { MTFont } from '../Models/mtfont';
import { OnboardingTemplate } from '../Models/onboarding_template';
import { NO_PARTNER, Partner } from '../Models/partners';
import { Recipe } from '../Models/recipe';
import { Ruleset } from '../Models/ruleset';
import { CondensedSeparator } from '../Models/separator';
import { Layout } from '../Models/template';
import { Type } from '../Models/type';
import { Task, UserModule } from '../Models/user';
import * as SharedActions from './shared.actions';
import { SimpleCollection } from '../Models/integration';

export const sharedFeatureKey = 'shared';

export const adapter: EntityAdapter<OnboardingTemplate> =
  createEntityAdapter<OnboardingTemplate>();

export const rulesAdapter: EntityAdapter<Ruleset> =
  createEntityAdapter<Ruleset>();

export const userModuleAdapter: EntityAdapter<UserModule> =
  createEntityAdapter<UserModule>({
    selectId: (userModule) => userModule.module,
  });

export interface SharedState {
  all_locations: SimpleLocation[];
  all_location_groups: LocationGroup[];
  all_locations_grouped: { [group: string]: SimpleLocation[] };
  current_location: number;
  form_errors: GeneralFormErrors;
  interface_language: InterfaceLanguage;
  side_panel_open: boolean;
  templates: EntityState<OnboardingTemplate>;
  templates_location: OnboardingTemplate[];
  templates_state: boolean;
  default_templates: OnboardingTemplate[];
  condensed_layouts: Layout[];
  ordertaking_layouts: Layout[];
  used_templates: OnboardingTemplate[];
  partner: Partner;
  rulesets: EntityState<Ruleset>;
  recipes_sidenav_top_offset: number;
  show_global_spinner: boolean;
  fonts: MTFont[];
  dishes_autocomplete: CondensedDish[];
  user_modules: EntityState<UserModule>;
  user_modules_loaded: boolean;
  ingredients_autocomplete: Ingredient[];
  allergens: Allergen[];
  additives: Additive[];
  labels: Label[];
  archives: Task[];
  sections_autocomplete: CondensedSeparator[];
  preview_html: string;
  preview_pdf_html: string;
  diets: Diet[];
  types: Type[];
  recipes: Recipe[];
  dishes_similar: {
    similar_allergens: Dish[];
    similar_additives: Dish[];
    similar_allergens_url_next: string;
    similar_additives_url_next: string;
    similar_allergens_count: number;
    similar_additives_count: number;
  };
  initial_menu_data_loaded: boolean;
  collections: SimpleCollection[];
}

const initialState: SharedState = {
  all_locations: null,
  all_location_groups: null,
  all_locations_grouped: null,
  current_location: null,
  form_errors: null,
  interface_language: null,
  side_panel_open: false,
  templates: adapter.getInitialState(),
  templates_location: null,
  templates_state: false,
  default_templates: null,
  condensed_layouts: [],
  ordertaking_layouts: [],
  used_templates: [],
  partner: NO_PARTNER,
  rulesets: rulesAdapter.getInitialState(),
  recipes_sidenav_top_offset: 0,
  show_global_spinner: false,
  fonts: null,
  dishes_autocomplete: [],
  user_modules: userModuleAdapter.getInitialState(),
  user_modules_loaded: false,
  ingredients_autocomplete: [],
  allergens: [],
  additives: [],
  labels: [],
  archives: [],
  sections_autocomplete: [],
  preview_html: null,
  preview_pdf_html: null,
  diets: [],
  types: [],
  recipes: [],
  dishes_similar: {
    similar_allergens: [],
    similar_additives: [],
    similar_allergens_url_next: '',
    similar_additives_url_next: '',
    similar_allergens_count: 0,
    similar_additives_count: 0,
  },
  initial_menu_data_loaded: false,
  collections: [],
};

const _sharedReducer = createReducer(
  initialState,
  on(SharedActions.setCurrentLocation, (state, { currentLocation }) => ({
    ...state,
    current_location: currentLocation,
  })),
  on(SharedActions.setInterfaceLang, (state, { lang }) => ({
    ...state,
    interface_language: lang,
  })),
  on(SharedActions.setSidePanelOpen, (state, { open }) => ({
    ...state,
    side_panel_open: open,
  })),
  on(SharedActions.setAllLocations, (state, { payload }) => {
    let groupedLocations: { [group: string]: SimpleLocation[] } = null;
    if (state.all_location_groups?.length && payload?.length) {
      const mapping = { null: '-' };
      state.all_location_groups.forEach((g) => (mapping[g.id] = g.name));
      groupedLocations = payload.reduce((r, a) => {
        r[mapping[a.location_group]] = r[mapping[a.location_group]] || [];
        r[mapping[a.location_group]].push(a);
        return r;
      }, Object.create(null));
    }
    return {
      ...state,
      all_locations: payload,
      all_locations_grouped: groupedLocations,
    };
  }),
  on(SharedActions.setAllLocationGroups, (state, { payload }) => {
    let groupedLocations: { [group: string]: SimpleLocation[] } = null;
    if (state.all_locations?.length && payload?.length) {
      const mapping = { null: '-' };
      payload.forEach((g) => (mapping[g.id] = g.name));
      groupedLocations = state.all_locations.reduce((r, a) => {
        r[mapping[a.location_group]] = r[mapping[a.location_group]] || [];
        r[mapping[a.location_group]].push(a);
        return r;
      }, Object.create(null));
    }
    return {
      ...state,
      all_location_groups: payload,
      all_locations_grouped: groupedLocations,
    };
  }),
  on(SharedActions.setTemplates, (state, { data }) => ({
    ...state,
    templates: adapter.setAll(data, state.templates),
    default_templates: data.filter((ot) => ot.is_default),
  })),
  on(SharedActions.setTemplatesLocation, (state, { data }) => ({
    ...state,
    templates_location: data,
  })),
  on(SharedActions.setTemplatesState, (state, { loading }) => ({
    ...state,
    templates_state: loading,
  })),
  on(SharedActions.addTemplate, (state, { template }) => ({
    ...state,
    templates: adapter.addOne(template, state.templates),
    default_templates: [template, ...state.default_templates],
  })),
  on(SharedActions.removeTemplate, (state, { id }) => ({
    ...state,
    templates: adapter.removeOne(id, state.templates),
    default_templates: state.default_templates.filter((ot) => ot.id !== id),
  })),
  on(SharedActions.setCondensedLayouts, (state, { payload }) => {
    return {
      ...state,
      condensed_layouts: payload,
    };
  }),
  on(SharedActions.setOrderTakingLayouts, (state, { payload }) => {
    return {
      ...state,
      ordertaking_layouts: payload,
    };
  }),
  on(SharedActions.setUsedTemplates, (state, { payload }) => {
    return {
      ...state,
      used_templates: payload,
    };
  }),
  on(SharedActions.setFieldErrors, (state, { payload }) => ({
    ...state,
    form_errors: { ...state.form_errors, ...payload },
  })),
  on(SharedActions.setFormErrors, (state, { error }) => {
    const form_errors = state?.form_errors;
    const nonFieldErrors = form_errors?.non_field_errors;
    let newAuthErrors: GeneralFormErrors;

    if (!form_errors) newAuthErrors = {};
    else newAuthErrors = { ...form_errors };

    if (!nonFieldErrors) newAuthErrors.non_field_errors = [error];
    else newAuthErrors.non_field_errors = [...nonFieldErrors, error];

    return {
      ...state,
      form_errors: newAuthErrors,
    };
  }),
  on(SharedActions.setPartner, (state, { partner }) => ({
    ...state,
    partner,
  })),
  on(SharedActions.setRules, (state, { payload }) => {
    return {
      ...state,
      rulesets: rulesAdapter.setAll(payload.results, state.rulesets),
    };
  }),
  on(SharedActions.setRecipesSidenavTopOffset, (state, { offset }) => {
    return {
      ...state,
      recipes_sidenav_top_offset: offset,
    };
  }),
  on(SharedActions.setGlobalSpinner, (state, { value }) => {
    return {
      ...state,
      show_global_spinner: value,
    };
  }),
  on(SharedActions.setFontsAsFilter, (state, { fonts }) => {
    return {
      ...state,
      fonts: fonts,
    };
  }),
  on(SharedActions.setDishesAutocomplete, (state, { payload }) => {
    return {
      ...state,
      dishes_autocomplete: payload?.results,
    };
  }),
  on(SharedActions.setAutocompleteSeparator, (state, { payload }) => {
    return {
      ...state,
      sections_autocomplete: payload,
    };
  }),
  on(SharedActions.setIngredientsAutocomplete, (state, { payload }) => {
    return {
      ...state,
      ingredients_autocomplete: payload.results,
    };
  }),
  on(SharedActions.setUserModules, (state, { userModules }) => ({
    ...state,
    user_modules: userModuleAdapter.setAll(userModules, state.user_modules),
  })),
  on(SharedActions.setUserModulesLoaded, (state) => ({
    ...state,
    user_modules_loaded: true,
  })),
  on(SharedActions.userModuleDisabled, (state, { module }) => ({
    ...state,
    user_modules: userModuleAdapter.removeOne(
      module.module,
      state.user_modules,
    ),
  })),
  on(SharedActions.userModuleEnabled, (state, { module }) => ({
    ...state,
    user_modules: userModuleAdapter.addOne(module, state.user_modules),
  })),
  on(SharedActions.userModuleUpdated, (state, { module }) => ({
    ...state,
    user_modules: userModuleAdapter.updateOne(
      {
        id: module.module,
        changes: module,
      },
      state.user_modules,
    ),
  })),
  on(SharedActions.setAllergens, (state, { result }) => {
    return {
      ...state,
      allergens: result,
    };
  }),
  on(SharedActions.setAdditives, (state, { result }) => {
    return {
      ...state,
      additives: result,
    };
  }),
  on(SharedActions.setLabels, (state, { result }) => {
    return {
      ...state,
      labels: result,
    };
  }),
  on(SharedActions.setArchives, (state, { archives }) => {
    return {
      ...state,
      archives: archives,
    };
  }),
  on(SharedActions.setPreviewHtml, (state, { html }) => {
    return {
      ...state,
      preview_html: html,
    };
  }),
  on(SharedActions.clearPreviewHtml, (state) => {
    return {
      ...state,
      preview_html: null,
    };
  }),

  on(SharedActions.setPreviewPdfHtml, (state, { html }) => {
    return {
      ...state,
      preview_pdf_html: html,
    };
  }),
  on(SharedActions.clearPreviewPdfHtml, (state) => {
    return {
      ...state,
      preview_pdf_html: null,
    };
  }),

  on(SharedActions.setDiets, (state, { diets }) => {
    return {
      ...state,
      diets,
    };
  }),
  on(SharedActions.setTypes, (state, { data }) => {
    return {
      ...state,
      types: data,
    };
  }),
  on(SharedActions.setRecipes, (state, { recipes }) => {
    return {
      ...state,
      recipes,
    };
  }),
  on(SharedActions.setSimilarDishesAdditives, (state, { result }) => {
    return {
      ...state,
      dishes_similar: {
        ...state.dishes_similar,
        similar_additives: result.results,
        similar_additives_url_next: result.next,
        similar_additives_count: result.count,
      },
    };
  }),
  on(SharedActions.setSimilarDishesAllergens, (state, { result }) => {
    return {
      ...state,
      dishes_similar: {
        ...state.dishes_similar,
        similar_allergens: result.results,
        similar_allergens_url_next: result.next,
        similar_allergens_count: result.count,
      },
    };
  }),

  on(SharedActions.addMoreDishes, (state, { dishes, nextUrl }) => {
    return {
      ...state,
      dishes_similar: {
        ...state.dishes_similar,
        similar_allergens:
          state.dishes_similar.similar_allergens.concat(dishes),
        similar_allergens_url_next: nextUrl,
      },
    };
  }),
  on(SharedActions.addMoreDishesAdditives, (state, { dishes, nextUrl }) => {
    return {
      ...state,
      dishes_similar: {
        ...state.dishes_similar,
        similar_additives:
          state.dishes_similar.similar_additives.concat(dishes),
        similar_additives_url_next: nextUrl,
      },
    };
  }),
  on(SharedActions.clearSimilarDishes, (state) => {
    return {
      ...state,
      dishes_similar: initialState.dishes_similar,
    };
  }),
  on(SharedActions.clearRecipesAutocomplete, (state) => {
    return {
      ...state,
      recipes: [],
    };
  }),
  on(SharedActions.setInitialMenuDataLoaded, (state, { value }) => {
    return {
      ...state,
      initial_menu_data_loaded: value,
    };
  }),
  on(SharedActions.clearData, () => {
    return {
      ...initialState,
    };
  }),
  on(SharedActions.setCollections, (state, { collections }) => {
    return {
      ...state,
      collections,
    };
  }),
);

export function sharedReducer(state: SharedState, action: Action) {
  return _sharedReducer(state, action);
}
