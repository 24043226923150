import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { BackgroundImage } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { SelectImageComponent } from '../../../shared/select-image/select-image.component';

@Component({
  selector: 'backgrounds-library',
  templateUrl: './backgrounds-library.component.html',
  styleUrls: ['./backgrounds-library.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    SelectImageComponent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    TranslocoPipe,
  ],
})
export class BackgroundsLibraryComponent {
  constructor(
    private dialogRef: MatDialogRef<BackgroundsLibraryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      backgroundImages: BackgroundImage[];
      currentBackground: number;
      deleteBackground: Function;
      select: Function;
    },
  ) {}

  delete(image: BackgroundImage): void {
    if (this.data.currentBackground === image.id) this.chooseBgImage(image);
    this.data.deleteBackground(image);
  }

  chooseBgImage(image: BackgroundImage): void {
    const deselect = this.data.currentBackground === image?.id;
    this.data.select(deselect ? null : image.id);
    if (deselect) {
      this.data.currentBackground = null;
    } else {
      this.dialogRef.close();
    }
  }
}
