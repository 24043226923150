<div class="select-wrapper" [class.has-label]="!hideLabel">
  @if (!hideLabel) {
    <label>{{ "style.sidebar.selects." + field | transloco }}</label>
  }
  <div class="element-wrapper">
    <mat-form-field
      class="expansion-panel-field select-small"
      subscriptSizing="dynamic"
      [mtTooltip]="
        disabledFields.includes(field) || disabled
          ? ('style.sidebar.restrictions.layout-field-locked' | transloco)
          : ''
      "
    >
      <mat-select
        class="select"
        (selectionChange)="selectChanged($event, field)"
        [disabled]="!menu?.style || disabledFields.includes(field)"
        [multiple]="multiple"
        [ngModel]="menu?.style ? (menu?.style)[field] : null"
        [placeholder]="
          placeholder
            ? placeholder
            : menu?.style[field] === null
              ? ('style.sidebar.selects.' +
                  (translationKey ? translationKey : field) +
                  '_options.none' | transloco)
              : ''
        "
      >
        @for (item of list; track item) {
          <mat-option [value]="item['name'] ? item['value'] : item.id">
            {{
              item["name"]
                ? ("style.sidebar.selects." +
                    (translationKey ? translationKey : field) +
                    "_options." +
                    item["name"] | transloco)
                : item[lang]
            }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (showLock) {
      <lock-item
        [field]="field"
        [lockedFields]="lockedFields"
        (updateMenu)="updateMenu.emit($event)"
      ></lock-item>
    }
  </div>
</div>
