import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatDatepickerInput,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import {
  TranslocoService,
  TranslocoDirective,
  TranslocoPipe,
} from '@jsverse/transloco';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Allergen } from 'src/app/shared/Models/declarations';
import { LangButton } from 'src/app/shared/Models/langButton';
import { Menu, MenuPreviewData } from 'src/app/shared/Models/menu';
import { Translation } from 'src/app/shared/Models/translation';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClipboardModule } from 'ngx-clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LanguageButtonsComponent } from '../../../../../shared/Components/language-buttons/language-buttons.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'linked-template',
  templateUrl: './linked-template.component.html',
  styleUrls: ['./linked-template.component.scss'],
  standalone: true,
  imports: [
    LanguageButtonsComponent,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    StopPropagationDirective,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    TranslocoDirective,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ClipboardModule,
    TranslocoPipe,
  ],
})
export class LinkedTemplateComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() allergens: Allergen[];
  @Input() analysisAllergen: boolean;
  @Input() costs: boolean;
  @Input() data: any;
  @Input() highlight = false;
  @Input() image: string;
  @Input() imageLarge = false;
  @Input() isProfitabilityReport: boolean;
  @Input() lang: ContentLanguage;
  @Input() overview: boolean;
  @Input() menu: Menu;
  @Input() numberLanguages = 1;
  @Input() showArchive = false;
  @Input() showDate = false;
  @Input() showDependants = false;
  @Input() showLangs = true;
  @Input() showPreview = true;
  @Input() showPrint = true;
  @Input() showShareLink = false;
  @Input() title: string;
  @Input() translations: Translation[];

  @Output() createArchive = new EventEmitter<void>();
  @Output() copied = new EventEmitter<void>();
  @Output() preview = new EventEmitter<MenuPreviewData>();
  @Output() print = new EventEmitter<{ params: any; onFulfilled: Function }>();

  @ViewChild('inputStart')
  startDate: MatDatepickerInput<Date>;
  @ViewChild('inputEnd')
  endDate: MatDatepickerInput<Date>;

  printLoading = false;
  privateLangs: LangButton[] = [];
  set langs(l) {
    this.privateLangs = l;
  }

  get langs() {
    return this.privateLangs;
  }
  minDate: Date;
  maxDate: Date;
  restLangs: number;
  highlights = new UntypedFormControl();
  filteredAllergens: Allergen[];
  tranWithout = 'without';
  tranNopork = 'pork';
  tranVeggie = 'nonveg';
  tranVega = 'nonvega';
  tranGlutf = 'nonglutf';
  tranLacf = 'nonlacf';
  shareLink: string;

  pork: Allergen;
  nonveg: Allergen;
  nonvega: Allergen;
  nonglutf: Allergen;
  nonlacf: Allergen;

  private destroyed$ = new Subject<void>();

  constructor(
    private utils: UtilsService,
    private translate: TranslocoService,
  ) {
    this.translate
      .selectTranslateObject('menus.linked_templates.highlights')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((t) => {
        this.tranWithout = t && t[this.tranWithout];
        this.tranNopork = t && t[this.tranNopork];
        this.tranVeggie = t && t[this.tranVeggie];
        this.tranVega = t && t[this.tranVega];
        this.tranGlutf = t && t[this.tranGlutf];
        this.tranLacf = t && t[this.tranLacf];

        const currentLang = this.translate.getActiveLang();
        this.pork = new Allergen({
          code: 'pork',
          [currentLang]: this.tranNopork,
        });
        this.nonveg = new Allergen({
          code: 'nonveg',
          [currentLang]: this.tranVeggie,
        });
        this.nonvega = new Allergen({
          code: 'nonvega',
          [currentLang]: this.tranVega,
        });
        this.nonglutf = new Allergen({
          code: 'nonglutf',
          [currentLang]: this.tranGlutf,
        });
        this.nonlacf = new Allergen({
          code: 'nonlacf',
          [currentLang]: this.tranLacf,
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      'menu' in changes &&
      this.menu?.date_range_max &&
      this.menu?.date_range_min
    ) {
      this.maxDate = new Date(this.menu.date_range_max);
      this.minDate = new Date(this.menu.date_range_min);
    }
    if (
      ('translations' in changes && this.menu) ||
      ('menu' in changes && this.translations)
    ) {
      this.langs = this.translations.map((translation: Translation) => ({
        lang: translation.language,
        activated: translation.language === this.menu.base_language,
        order: translation.language === this.menu.base_language ? 0 : null,
      }));
      this.updateLink(this.langs);
    }
    if ('allergens' in changes && this.allergens) {
      this.filteredAllergens = [
        ...(this.showDependants
          ? this.allergens
          : this.allergens.filter((all) => !all.depends_on)),
        this.pork,
        this.nonveg,
        this.nonvega,
        this.nonglutf,
        this.nonlacf,
      ];
    }
  }

  ngAfterViewInit() {
    if (this.startDate && this.endDate) {
      this.startDate.dateChange.subscribe(
        ({ value }) => (this.endDate.min = value),
      );
      this.endDate.dateChange.subscribe(
        ({ value }) => (this.startDate.max = value),
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateLink(langs: LangButton[]) {
    this.langs = langs;
    this.shareLink = this.getLink(langs);
  }

  getSymbols(): string {
    if (!this.highlights.value) return undefined;
    return this.highlights.value
      .slice(0, 2)
      .map((code) => this.utils.getAllergenSymbol(code))
      .join('');
  }

  getButtonState(): boolean {
    if (!this.data || !this.data.multilingual_required) return false;
    const count = this.langs.filter((v) => v.activated).length;
    if (count < this.data.number_languages) {
      this.restLangs = this.data.number_languages - count;
      return true;
    }
    return false;
  }

  showAddMore(): boolean {
    if (!this.data) return undefined;
    return (
      this.data.multilingual_required &&
      this.translations.length < this.data.number_languages
    );
  }

  getActionParams() {
    const params = this.utils.getParams(
      this.langs,
      this.menu.base_language,
      this.data,
      null,
      this.getHighlights(),
    );
    if (this.showDate) {
      const startDate =
        this.startDate.value &&
        formatDate(this.startDate.value, 'yyyy-MM-dd', this.lang);
      const endDate =
        this.endDate.value &&
        formatDate(this.endDate.value, 'yyyy-MM-dd', this.lang);
      if (startDate) params['start_date'] = startDate;
      if (endDate) params['end_date'] = endDate;
    }
    if (this.overview) params['menu'] = this.menu.id;
    return params;
  }

  createPreview() {
    const params = this.getActionParams();
    return this.preview.emit({
      url: '',
      params,
      baseLanguage: this.langs.filter((l) => l.activated)[0].lang,
      langs: this.langs,
    });
  }

  printMenu() {
    const params = this.getActionParams();
    this.printLoading = true;
    this.print.emit({
      params: _.omitBy(params, _.isNil),
      onFulfilled: () => {
        this.printLoading = false;
      },
    });
  }

  getLink(langs: LangButton[]) {
    let params = this.getHighlights() || '';
    if (params && params.highlight && params.highlight.length)
      params = params.highlight.map((code) => `highlight=${code}`).join('&');

    return `${this.utils.getSharedLink(
      langs,
      this.menu.base_language,
      this.data,
      this.menu.share_link,
    )}${params ? '&' + params : ''}`;
  }

  getHighlighted(): string {
    this.updateLink(this.langs);
    if (this.highlights.value && this.highlights.value.length)
      return this.filteredAllergens
        .filter((all) => this.highlights.value.includes(all.code))
        .map((v) => {
          if (['nonveg', 'nonvega', 'nonlacf', 'nonglutf'].includes(v.code))
            return v[this.lang];
          return this.tranWithout.replace('{{name}}', v[this.lang]);
        })
        .join('; ');
    return null;
  }

  getHighlights(): any {
    if (this.highlights.value && this.highlights.value.length)
      return { highlight: this.highlights.value };
    return null;
  }

  isAllergenSelected(code: string): boolean {
    return (
      this.highlights.value && (<string[]>this.highlights.value).includes(code)
    );
  }
}
