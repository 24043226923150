import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import { Dish, Spellcheck } from 'src/app/shared/Models/dish';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { Separator } from 'src/app/shared/Models/separator';
import { User } from 'src/app/shared/Models/user';
import { showSnackbarMessage } from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';

import { addWordToUserDictionary } from '../../write/ngrx/menu-write.actions';
import { SidePanelPlaceholderComponent } from '../../../../shared/Components/sidepanel/side-panel-placeholder/side-panel-placeholder.component';
import { SpinnerComponent } from '../../../../shared/Components/spinner/spinner.component';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { CopyDataLineComponent } from '../../../../shared/Components/copy-data-line/copy-data-line.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { GrammarCorrectionsComponent } from '../../../../shared/Components/grammar-corrections/grammar-corrections.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SidePanelHeaderComponent } from '../../../../shared/Components/sidepanel/side-panel-header/side-panel-header.component';
import { GoBackComponent } from '../../../../shared/Components/sidepanel/go-back/go-back.component';

@Component({
  selector: 'similar-translation',
  templateUrl: './similar-translation.component.html',
  styleUrls: ['./similar-translation.component.css'],
  standalone: true,
  imports: [
    GoBackComponent,
    SidePanelHeaderComponent,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    GrammarCorrectionsComponent,
    MatButtonModule,
    MatIconModule,
    CopyDataLineComponent,
    TranslocoDirective,
    SpinnerComponent,
    SidePanelPlaceholderComponent,
    TranslocoPipe,
  ],
})
export class SimilarTranslationComponent implements OnChanges {
  @Input() baseLanguage;
  @Input() currentDish: MenuDish;
  @Input() description: string;
  @Input() dishes;
  @Input() dishTitle: string;
  @Input() isDescription = false;
  @Input() isLoading: boolean;
  @Input() lang: ContentLanguage;
  @Input() numberOfItems: number;
  @Input() spellcheckItem: Spellcheck;
  @Input() tags: { value: string; selected: boolean }[] = [];
  @Input() user: User;

  @Output() ignoreSpellcheck = new EventEmitter();
  @Output() changeItem = new EventEmitter<boolean>();
  @Output() fetchMoreItems = new EventEmitter<void>();
  @Output() similar = new EventEmitter<BaseNameExtended>();
  @Output() openTranslations: EventEmitter<MenuDish> = new EventEmitter();
  @Output() updateSimilar = new EventEmitter<any>();
  @Output() clearSelectedDish = new EventEmitter();
  @Output() fetchSpellcheck = new EventEmitter<number>();

  details: Separator | Dish;
  isSelectedAll = true;
  rest: number;
  rtl = false;
  showGrammar: boolean;
  showMore = false;
  showPlaceholder = false;

  constructor(
    private ngrxStore: Store<State>,
    private utils: UtilsService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('lang' in changes && this.lang) {
      this.rtl = rtlLangs.includes(this.lang);
    }
    this.rest = this.numberOfItems - (this.dishes ? this.dishes.length : 0);
    if ('currentDish' in changes && this.currentDish) {
      this.details =
        this.currentDish.dish_detail || this.currentDish.separator_detail;
      const spellcheckId = this.details?.[`spellcheck_` + this.lang];
      this.showGrammar = !!spellcheckId;
      if (this.showGrammar) {
        this.fetchSpellcheck.emit(spellcheckId);
      }
    }
  }

  addWord(word: string): void {
    const onFulfilled = (md: MenuDish) => {
      this.utils.getTranslation(
        'menus.shared.grammar-added',
        (message: string) => {
          this.ngrxStore.dispatch(showSnackbarMessage({ message }));
        },
      );
    };
    const text = (this.currentDish?.dish_detail ||
      this.currentDish?.separator_detail)[this.lang];
    this.ngrxStore.dispatch(
      addWordToUserDictionary({
        word,
        text,
        lang: this.lang,
        menuDish: this.currentDish,
        onFulfilled,
      }),
    );
  }

  getTitle() {
    return this.tags
      .filter((t) => t.selected)
      .map((t) => t.value)
      .join(' ');
  }

  chooseSimilar(menudish: BaseNameExtended) {
    this.similar.emit(menudish);
  }

  getTags() {
    return this.showMore ? this.tags : this.tags.slice(0, 15);
  }

  changeTag(tag) {
    const deselected = this.tags.filter((v) => !v.selected);
    if (deselected.length === 0) {
      this.tags.forEach((t) => (t.selected = false));
    }
    tag.selected = !tag.selected;

    if (!tag.selected || deselected.length === 0) {
      this.isSelectedAll = false;
    }
    if (deselected.length === this.tags.length - 1 && !tag.selected) {
      this.showPlaceholder = true;
    } else {
      this.showPlaceholder = false;
      this.updateSimilar.emit(this.currentDish);
    }
  }

  selectAll() {
    if (this.isSelectedAll) {
      this.tags.forEach((t) => (t.selected = false));
      this.showPlaceholder = true;
      this.isSelectedAll = false;
    } else {
      this.isSelectedAll = true;
      this.showPlaceholder = false;
      this.tags.forEach((t) => (t.selected = true));
    }
    this.updateSimilar.emit(this.currentDish);
  }
}
