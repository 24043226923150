import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[stop-propagation]',
  standalone: true,
})
export class StopPropagationDirective implements OnInit, OnChanges {
  @Input() stopPropClassName: string;
  @Input() lazyPropagation = false;
  @Input() updateParent: EventEmitter<boolean>;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    if (this.stopPropClassName && !this.lazyPropagation) {
      this.setListener();
    }
  }

  setListener() {
    let native = this.el.nativeElement;
    while (
      !native.classList ||
      (!native.classList.contains(this.stopPropClassName) &&
        native.parentElement)
    ) {
      native = native.parentElement;
    }
    if (
      !native.classList ||
      !native.classList.contains(this.stopPropClassName)
    ) {
      return undefined;
    }
    this.renderer.listen(native, 'click', (event: any) => {
      event.stopPropagation();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('updateParent' in changes) {
      this.updateParent.subscribe((v) => this.setListener());
    }
  }

  @HostListener('click', ['$event'])
  public onClick(event: any): void | boolean {
    event.stopPropagation();
  }
}
