"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getEventsWithMatchingKey(eventsMap, actionType) {
    var objectKeys = Object.keys(eventsMap);
    var symbolKeys = Object.getOwnPropertySymbols
        ? Object.getOwnPropertySymbols(eventsMap)
        : [];
    return objectKeys.concat(symbolKeys).filter(function (key) { return key === '*' || key === actionType; })
        .map(function (matchingKey) { return eventsMap[matchingKey]; });
}
exports.default = getEventsWithMatchingKey;
