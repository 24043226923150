import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { setUserOnboardingTemplateUsed } from 'src/app/dashboard/ngrx/dashboard.actions';
import { State } from 'src/app/reducers';
import { DeepPartial, Results } from 'src/app/shared/Models/generics';
import {
  BackgroundImage,
  CreatePresetFromMenu,
  Menu,
  MenuAnalysis,
  MenuAnalysisType,
  MenuBackup,
  MenuPreset,
} from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';
import {
  handleHttpError,
  setGlobalSpinner,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { selectCurrentLocation } from 'src/app/shared/ngrx/shared.selectors';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  fetchUser,
  incrementAiUsage,
} from 'src/app/shared/user/ngrx/user.actions';
import {
  selectAiLimits,
  selectRemainingAiCredits,
  selectUserOrganisation,
} from 'src/app/shared/user/ngrx/user.selectors';
import { EMPTY } from 'rxjs';
import {
  catchError,
  finalize,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  clearCurrentMenuTranslations,
  fetchMenuTranslations,
} from '../translate/ngrx/menu-translate.actions';
import {
  clearCurrentMenuDishes,
  fetchMenuDishes,
  setLoading,
  setMenuDishes,
} from '../write/ngrx/menu-write.actions';
import { TreeManagerService } from './../../tree-manager.service';
import * as MenuEditActions from './menu-edit.actions';
import { selectCurrentMenu } from './menu-edit.selectors';
import { MatDialog } from '@angular/material/dialog';
import { MenuAnalysisComponent } from '../write/menu-analysis/menu-analysis.component';
import { FileService } from 'src/app/shared/Services/files/files.service';

@Injectable()
export class MenuEditEffects {
  clearCurrentMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.clearCurrentMenu),
      withLatestFrom(this.store.select(selectUserOrganisation)),
      switchMap(([, org]) => [
        clearCurrentMenuDishes(),
        clearCurrentMenuTranslations(),
      ]),
    ),
  );

  fetchSpellcheckItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.fetchSpellcheckItem),
      switchMap(({ spellcheck_id }) =>
        this.genericsService
          .get<any>(`${this.configService.spellcheckItem}${spellcheck_id}/`)
          .pipe(
            mergeMap((item) => [
              MenuEditActions.setCurrentSpellcheckItem({ payload: item }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  getCurrentMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.getCurrentMenu),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ id, hard = false }, currentMenu]) => {
        const currentId = currentMenu ? currentMenu.id : null;
        if (id === currentId && !hard) return [];
        return this.genericsService
          .get<any>(`${this.configService.menus}${id}/`)
          .pipe(
            mergeMap((menu) => [
              MenuEditActions.setCurrentMenu({ menu }),
              fetchMenuDishes({ params: { condensed: true } }),
            ]),
            catchError((error) => {
              if (error.status === 404) {
                this.router.navigate(['/404']);
              }
              return [handleHttpError({ error })];
            }),
          );
      }),
    ),
  );

  createMenuFromOnboardingTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.createMenuFromOnboardingTemplate),
      withLatestFrom(this.store.select(selectCurrentLocation)),
      switchMap(([{ date, id }, location]) => {
        return this.genericsService
          .post<
            { date: string },
            Menu
          >(`${this.configService.onboardingTemplates}${id}/create_menu/`, { date: date }, UtilsService.addLocationParam(location, null))
          .pipe(
            mergeMap((menu) => {
              this.store.dispatch(setUserOnboardingTemplateUsed({ id, menu }));
              this.store.dispatch(MenuEditActions.afterMenuCreated({ menu }));
              return [];
            }),
            catchError((error) => {
              return [handleHttpError({ error })];
            }),
          );
      }),
    ),
  );

  uploadBackgroundImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.uploadBackgroundImage),
      switchMap(({ data, params, imageType, callback }) => {
        const formData = new FormData();
        formData.append('background_image', data.payload);
        if (imageType) {
          formData.append('type', imageType.toString());
        }
        return this.genericsService
          .post<
            FormData,
            BackgroundImage
          >(this.configService.backgrounds, formData, params)
          .pipe(
            mergeMap((image: BackgroundImage) => {
              this.store.dispatch(
                MenuEditActions.addBackgroundImage({ image }),
              );
              data.onFulfilled?.(image.id);
              if (callback) callback(image.id);
              return [];
            }),
            catchError((error) => [handleHttpError({ error })]),
          );
      }),
    ),
  );

  fetchBackgroundImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.fetchBackgroundImages),
      switchMap(({ params }) =>
        this.genericsService
          .get<
            Results<BackgroundImage>
          >(`${this.configService.backgrounds}`, params)
          .pipe(
            mergeMap(({ results: images }) => [
              MenuEditActions.setBackgroundImages({ images }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  deleteBackgroundImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.deleteBackgroundImage),
      switchMap(({ image }) =>
        this.genericsService.delete(image.url).pipe(
          mergeMap(() => [
            MenuEditActions.removeBackgroundImage({ imageId: image.id }),
          ]),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  createMenuBackup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.createMenuBackup),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([, currentMenu]) => {
        if (!currentMenu.id) return undefined;
        return this.genericsService
          .post<
            {},
            MenuBackup
          >(`${this.configService.menus}${currentMenu.id}/create_backup/`, {})
          .pipe(
            mergeMap((newBackup) => [
              MenuEditActions.addCreatedBackup({ backup: newBackup }),
            ]),
            catchError((error) => {
              return [handleHttpError({ error })];
            }),
          );
      }),
    ),
  );

  restoreMenuBackup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.restoreMenuBackup),
      switchMap(({ id }) => {
        if (!id) return undefined;
        return this.genericsService
          .post<
            { reset_menu: boolean },
            Menu
          >(`${this.configService.menus}${id}/restore_backup/`, { reset_menu: true })
          .pipe(
            mergeMap((menu) => [MenuEditActions.afterMenuCreated({ menu })]),
            catchError((error) => {
              return [handleHttpError({ error })];
            }),
          );
      }),
    ),
  );

  afterMenuCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MenuEditActions.afterMenuCreated),
        switchMap(({ menu }) => {
          this.store.dispatch(fetchUser());
          this.store.dispatch(fetchMenuTranslations({ menu }));
          this.store.dispatch(setGlobalSpinner({ value: false }));
          this.router.navigate([`/menus`, menu.id, 1]);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  deleteMenuBackup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.deleteMenuBackup),
      switchMap(({ backup }) =>
        this.genericsService.delete(backup.url).pipe(
          mergeMap(() => [
            MenuEditActions.removeBackupMenu({ backupId: backup.id }),
          ]),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  modifyOrderTaking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.modifyOrderTaking),
      switchMap(({ menu, state }) =>
        this.genericsService
          .post<
            {},
            { message: string }
          >(`${menu.url}${state ? 'enable' : 'disable'}_ordertaking/`, {})
          .pipe(
            mergeMap(() => [MenuEditActions.setOrderTaking({ value: state })]),
          ),
      ),
      catchError((error) => [handleHttpError({ error })]),
    ),
  );

  patchMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.patchMenu),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(
        ([{ payload, setAsCurrent, callback, showSnack }, currentMenu]) => {
          const { forceUpdateDishes, ...data } = payload;
          if (forceUpdateDishes)
            this.store.dispatch(setLoading({ loading: true }));
          return this.genericsService
            .patch<DeepPartial<Menu>, Menu>(currentMenu.url, data)
            .pipe(
              finalize(() => callback?.()),
              withLatestFrom(this.store.select(selectCurrentMenu)),
              mergeMap(([menu, updatedCurrentMenu]) => {
                if (forceUpdateDishes)
                  this.store.dispatch(
                    fetchMenuDishes({ params: { condensed: true } }),
                  );
                if (
                  !data?.style?.locked_fields ||
                  Object.keys(data.style).length > 1
                ) {
                  this.utils.refreshPdfView.next();
                }
                if (updatedCurrentMenu === null) return [];
                return [
                  ...(setAsCurrent
                    ? [MenuEditActions.setCurrentMenu({ menu })]
                    : []),
                  ...(showSnack
                    ? [
                        showSnackbarMessage({
                          message: this.translate.translate(
                            'shared.messages.changes-saved',
                          ),
                          snackClass: 'success',
                        }),
                      ]
                    : []),
                  MenuEditActions.setStyleModified({
                    value: menu.style_modified,
                  }),
                ];
              }),
              catchError((error) => [handleHttpError({ error })]),
            );
        },
      ),
    ),
  );

  createUpdatePreset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.createUpdatePreset),
      switchMap(({ preset, id, existing }) =>
        this.genericsService
          .post<
            CreatePresetFromMenu,
            MenuPreset
          >(`${this.configService.menus}${id}/create_preset/`, { ...preset, delete_existing: existing })
          .pipe(
            mergeMap(() => {
              const message = this.translate.translate(
                `write.blocks.sidebar.preset.preset_${
                  existing ? 'updated' : 'created'
                }`,
              );
              return [
                showSnackbarMessage({ message }),
                MenuEditActions.setPreset({ data: true }),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  saveFieldDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.saveFieldDefault),
      switchMap(({ url, data }) =>
        this.genericsService
          .post<
            {
              target: string;
              field?: string;
            },
            { message: string }
          >(`${url}save_field_default/`, data)
          .pipe(
            mergeMap(() => EMPTY),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  restoreFieldDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.restoreFieldDefault),
      switchMap(({ url, data }) =>
        this.genericsService
          .post<
            {
              target: string;
              field?: string;
            },
            Menu
          >(`${url}restore_field_default/`, data)
          .pipe(
            mergeMap((newMenu: Menu) => [
              MenuEditActions.setCurrentMenu({ menu: newMenu }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  checkMenuGrammar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.checkMenuGrammar),
      tap(() => {
        this.store.dispatch(setLoading({ loading: true }));
      }),
      switchMap(({ url, language }) =>
        this.genericsService
          .post<{ language: string }, MenuDish[]>(`${url}grammar_check/`, {
            language,
          })
          .pipe(
            mergeMap((menudishes) => {
              this.treeManagerService.clearTree();
              return [
                setMenuDishes({ menuDishes: menudishes }),
                setLoading({ loading: false }),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchMenuAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.fetchMenuAnalysis),
      withLatestFrom(
        this.store.select(selectAiLimits),
        this.store.select(selectRemainingAiCredits),
      ),
      tap(() => {
        this.store.dispatch(
          MenuEditActions.setAiAnalysisLoading({ value: true }),
        );
      }),
      switchMap(([{ url, lang, analysis_type }, credits, remainingCredits]) =>
        this.genericsService
          .post<
            { lang: string; analysis_type: MenuAnalysisType },
            MenuAnalysis
          >(`${url}generate_analysis/`, { lang, analysis_type })
          .pipe(
            mergeMap((result) => {
              this.dialog.open(MenuAnalysisComponent, {
                data: {
                  ...result,
                  analysis_type,
                  credits: credits.menu_analysis,
                  remaining_credits: remainingCredits.menu_analysis - 1,
                  download_analysis: () => {
                    this.store.dispatch(
                      MenuEditActions.downloadMenuAnalysis({
                        url,
                        lang,
                        analysis_type,
                        data: result,
                      }),
                    );
                  },
                },
                width: '950px',
                maxWidth: '95vw',
                disableClose: true,
              });
              return [
                MenuEditActions.setAiAnalysisLoading({ value: false }),
                incrementAiUsage({ credit: 'menu_analysis', amount: 1 }),
              ];
            }),
            catchError((error) => [
              MenuEditActions.setAiAnalysisLoading({ value: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  downloadMenuAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.downloadMenuAnalysis),
      switchMap(({ url, lang, analysis_type, data, onFulfilled }) => {
        this.fileService
          .downloadFile(
            `${url}preview_analysis/`,
            {},
            { ...data, analysis_type, lang },
          )
          .add(() => {
            onFulfilled?.();
          });
        return [];
      }),
    ),
  );

  exportMenuToExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuEditActions.exportMenuToExcel),
      switchMap(({ menu }) => {
        this.fileService.downloadFile(
          this.configService.menuImport,
          {},
          { menu: menu.id },
          undefined,
          false,
          true,
          true,
        );
        return [];
      }),
      catchError((error) => [handleHttpError({ error, forceSnackbar: true })]),
    ),
  );

  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private dialog: MatDialog,
    private fileService: FileService,
    private genericsService: GenericsService,
    private router: Router,
    private store: Store<State>,
    private treeManagerService: TreeManagerService,
    private utils: UtilsService,
    protected translate: TranslocoService,
  ) {}
}
