@if (hasModule("all") || hasModule("add")) {
  <h3>
    {{ "menus.linked_templates.titles.declarations" | transloco }}
  </h3>
}
@if (hasModule("all") || hasModule("add")) {
  <mat-card>
    <div class="wrapper">
      @if (!(menu && menu.url)) {
        <spinner></spinner>
      } @else {
        @for (template of hardcodedLinkedTemplates; track template) {
          <linked-template
            class="template"
            [allergens]="allergens$ | async"
            [data]="template"
            [image]="template?.image"
            [imageLarge]="true"
            [lang]="lang"
            [translations]="translations"
            [menu]="menu"
            [showShareLink]="true"
            [title]="template[lang]"
            (print)="printMenu($event, 'preview')"
            (copied)="copied()"
            (preview)="showPreview($event, 'preview')"
          ></linked-template>
        }
        @if (menu?.template_detail && hasModule("all")) {
          <linked-template
            class="template"
            [showDependants]="
              hasModuleSetting('all', 'extended_declaration', true)
            "
            [highlight]="true"
            [allergens]="allergens$ | async"
            [data]="menu?.template_detail"
            [lang]="lang"
            [showShareLink]="true"
            [title]="'menus.linked_templates.highlights.menu' | transloco"
            [translations]="translations"
            [menu]="menu"
            [numberLanguages]="menu.template_detail?.number_languages"
            (print)="printMenu($event, 'preview')"
            (copied)="copied()"
            (preview)="showPreview($event, 'preview')"
          >
          </linked-template>
        }
        @if (hasModule("all")) {
          <linked-template
            [analysisAllergen]="true"
            class="template"
            [lang]="lang"
            [title]="'menus.linked_templates.analysis_allergens' | transloco"
            [translations]="translations"
            [menu]="menu"
            (print)="
              printMenu(
                $event,
                '/menus/' + menu.id + '/analysis_allergens/',
                true
              )
            "
            (preview)="
              showPreview(
                $event,
                '/menus/' + menu.id + '/analysis_allergens/',
                true
              )
            "
          >
          </linked-template>
        }
      }
    </div>
  </mat-card>
}

@if (
  hasModule("procu") ||
  (hasModule("man") && hasModule("recip")) ||
  hasModule("diver")
) {
  <h3>
    {{ "menus.linked_templates.titles.recipes" | transloco }}
  </h3>
}
@if (
  hasModule("procu") ||
  (hasModule("man") && hasModule("recip")) ||
  hasModule("diver")
) {
  <mat-card>
    <div class="wrapper">
      @if (!(menu && menu.url)) {
        <spinner></spinner>
      } @else {
        @if (hasModule("man") && hasModule("recip")) {
          <linked-template
            class="template"
            [image]="'assets/img/reports/productsheets.svg'"
            [lang]="lang"
            [showArchive]="true"
            [showLangs]="false"
            [translations]="translations"
            [menu]="menu"
            [showPreview]="false"
            [showPrint]="false"
            [title]="'menus.linked_templates.product_sheets' | transloco"
            (createArchive)="createArchive.emit({ menu, task_type: 2 })"
            (print)="
              printMenu($event, '/menus/' + menu.id + '/product_sheets/', true)
            "
            (preview)="
              showPreview(
                $event,
                '/menus/' + menu.id + '/product_sheets/',
                true
              )
            "
          ></linked-template>
        }
        @if (hasModule("procu")) {
          <linked-template
            class="template"
            [image]="'assets/img/reports/procurementquantities.svg'"
            [lang]="lang"
            [menu]="menu"
            [showDate]="true"
            [title]="'menus.linked_templates.procurement' | transloco"
            [translations]="translations"
            (print)="printMenu($event, 'procurement_preview')"
            (copied)="copied()"
            (preview)="showPreview($event, 'procurement_preview')"
          >
          </linked-template>
        }
        @if (hasModule("diver")) {
          <linked-template
            class="template"
            [image]="'assets/img/reports/mealdiversity.svg'"
            [lang]="lang"
            [title]="'menus.linked_templates.analysis' | transloco"
            [translations]="translations"
            [menu]="menu"
            (print)="printMenu($event, 'analysis_preview')"
            (preview)="showPreview($event, 'analysis_preview')"
          >
          </linked-template>
        }
      }
    </div>
  </mat-card>
}

<h3>
  {{ "menus.linked_templates.titles.business" | transloco }}
</h3>
<mat-card>
  <div class="wrapper">
    @if (!(menu && menu.url)) {
      <spinner></spinner>
    } @else {
      @if (hasModule("procu")) {
        <linked-template
          class="template"
          [costs]="true"
          [image]="'assets/img/reports/cost.svg'"
          [lang]="lang"
          [translations]="translations"
          [menu]="menu"
          [showDate]="true"
          [title]="'menus.linked_templates.costs' | transloco"
          (print)="printMenu($event, 'costs_preview')"
          (copied)="copied()"
          (preview)="showPreview($event, 'costs_preview')"
        >
        </linked-template>
      }
      @if (hasModule("man")) {
        <linked-template
          class="template"
          [image]="'assets/img/reports/profitability.svg'"
          [lang]="lang"
          [translations]="translations"
          [menu]="menu"
          [overview]="true"
          [showLangs]="false"
          [isProfitabilityReport]="true"
          [title]="'menus.linked_templates.overview' | transloco"
          (print)="printMenu($event, '/dishes/reports/', true)"
          (preview)="showPreview($event, '/dishes/reports/', true)"
        ></linked-template>
      }
      <app-download-stats
        [created]="menu.created_at"
        [lang]="interfaceLang"
        (download)="downloadStats.emit($event)"
      ></app-download-stats>
    }
  </div>
</mat-card>
