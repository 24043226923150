<div
  class="translate-container"
  [class.inactive]="data?.user_details?.translation_disabled"
  [hidden]="data?.user_details?.translation_disabled && showRequired"
>
  <form class="form" [attr.dir]="rtlLangs.includes(language) ? 'rtl' : 'ltr'">
    @if (fields.item.isEmpty || fields.item.isAutotranslated) {
      <div
        [class.empty-name]="fields.item.isEmpty"
        [class.autotranslated]="fields.item.isAutotranslated"
      ></div>
    }
    <mat-form-field>
      @if (showGrammarIcon) {
        <grammar-check-button
          [value]="data && data[language]"
          (clicked)="showSimilarTranslations()"
        ></grammar-check-button>
      }
      @if (data) {
        <mat-label>{{ data[baseLanguage] }}</mat-label>
      }
      <input
        #dishInput
        #trigger="matAutocompleteTrigger"
        matInput
        class="translate-input"
        name="translation"
        minlength="2"
        maxlength="250"
        [formControl]="transControl"
        [matAutocomplete]="auto"
        (focusin)="
          showSimilarTranslations(false, false, false, true);
          dishInputFocused = true
        "
        (focusout)="submitDish($event, transControl)"
        (keydown.enter)="blurItemInput(dishInput); trigger.closePanel()"
      />
    </mat-form-field>
    @if (showLoader === "item") {
      <mat-progress-bar
        class="progress-bar"
        mode="indeterminate"
      ></mat-progress-bar>
    }
    <span class="icon-button-density-3">
      <button
        mat-icon-button
        color="primary"
        (click)="showSimilarTranslations()"
      >
        <mat-icon aria-hidden="true" stop-propagation> translate </mat-icon>
      </button>
    </span>
    <mat-autocomplete #auto="matAutocomplete">
      @for (option of samedish; track option) {
        <mat-option
          (mousedown)="wtf($event)"
          (click)="selectDish(option)"
          [value]="option && option[language]"
        >
          {{ option && option[language] }}
        </mat-option>
      }
    </mat-autocomplete>
  </form>
</div>

@if (transControl.errors && (transControl.dirty || transControl.touched)) {
  <div class="hints">
    @for (field of ["required", "minlength", "maxlength"]; track field) {
      <span [hidden]="!transControl.errors[field]">
        {{ "dishes.translation.validation." + field | transloco }}
      </span>
    }
  </div>
}

@for (field of fields | keyvalue; track field) {
  <div
    class="description-block"
    [attr.dir]="rtlLangs.includes(language) ? 'rtl' : 'ltr'"
  >
    @if (
      field.value.field &&
      ((field.value.object === "data" &&
        (data?.user_details[field.value.field + baseLanguage] ||
          data?.user_details[field.value.field + language])) ||
        (field.value.object === "menudish" &&
          (menudish?.user_details[field.value.field + baseLanguage] ||
            menudish?.user_details[field.value.field + language])))
    ) {
      @if (field.value.isEmpty || field.value.isAutotranslated) {
        <div
          [class.empty-name]="field.value.isEmpty"
          [class.autotranslated]="field.value.isAutotranslated"
        ></div>
      }
      <p
        class="description"
        [class.description-exists]="
          field.value.object === 'data'
            ? data?.user_details[field.value.field + language]
            : menudish?.user_details[field.value.field + language]
        "
      >
        {{
          field.value.object === "data"
            ? data?.user_details[field.value.field + baseLanguage]
            : menudish?.user_details[field.value.field + baseLanguage]
        }}
      </p>
      <div class="description-wrapper">
        <mat-form-field>
          <mat-label>{{
            ("dishes.translation.translate_to" | transloco) +
              " " +
              ("shared.languages." + language | transloco)
          }}</mat-label>
          <textarea
            matInput
            maxlength="2000"
            [name]="field.value.name"
            [ngModel]="
              field.value.object === 'data'
                ? data.user_details[field.value.field + language]
                : menudish.user_details[field.value.field + language]
            "
            (focusin)="
              showSimilarTranslations(
                true,
                field.value.object === 'menudish',
                field.value.name.includes('secondary'),
                true
              )
            "
            (focusout)="
              submitDish(
                $event,
                transControl,
                field.value.object === 'menudish'
              )
            "
          ></textarea>
        </mat-form-field>
        @if (
          (showLoader === "description" && field.value.object === "data") ||
          (showLoader === "other" && field.value.object === "menudish")
        ) {
          <mat-progress-bar
            class="progress-bar"
            mode="indeterminate"
          ></mat-progress-bar>
        }
        <span class="icon-button-density-3">
          <button
            mat-icon-button
            color="primary"
            (click)="
              showSimilarTranslations(
                true,
                field.value.object === 'menudish',
                field.value.name.includes('secondary')
              )
            "
          >
            <mat-icon>translate</mat-icon>
          </button>
        </span>
      </div>
    }
  </div>
}
